import { useState, useEffect, useMemo } from "react";
import { useQuery, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { useParams } from "@tanstack/react-router";

import EventDetailsTab from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Details";
import EventOrganizeTab from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Organize";
import EventRegistrationsTab from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Registrations";
import Header from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Header";
import { useGameStore } from "@/store/useGameStore";
import { useRootStore } from "@/store/useRootStore";
import EventMarketingTab from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Marketing";
import { resourceImageOptions } from "@/routes/_authenticated/entity/$entityId";
import { useEventStore } from "@/store/useEventStore";
import { webAxiosInstance } from "@/utils/axios";
import { eventQueryOptions } from "@/data/organization/queries";
import { pusher } from "@/lib/pusher";
import { fetchEventRegistrationsQueryOptions } from "@repo/api/web";
import EventStandingsTab from "./Tabs/Standings";
import { Button } from "@repo/ui/button";

const SingleEventPage = () => {
  const { entityId, eventId } = useParams({ strict: false });
  const { setDefault, setShouldSetDefaultEventState, shouldSetDefaultEventState, refetch, activePhasePod } = useEventStore();
  const { games } = useGameStore();
  const { userData } = useRootStore();
  const queryClient = useQueryClient();

  const establishment = useMemo(() => {
    const found = userData?.establishments?.find((est) => est.entityId === entityId);
    return found || null;
  }, [userData, entityId]);

  const { data: images } = useSuspenseQuery(
    resourceImageOptions({ establishmentId: establishment?.id })
  );

  useEffect(() => {
    if (establishment && images) {
      const logoImage = images.find((image) => image.imageType === 'logo');
      establishment.logoUrl = logoImage?.image?.url;
    }
  }, [establishment, images]);

  const { data: eventData } = useQuery(eventQueryOptions(eventId));
  const activity = eventData?.data?.activities?.[0];

  useEffect(() => {
    if (!activity?.id) return;

    const pusherChannel = pusher.subscribe(`activity-${activity.id}`);
    const handleRefresh = () => {
      console.log('refresh');
      setShouldSetDefaultEventState(true);
      refetch();
      queryClient.invalidateQueries([activity.id, 'activity']);
      queryClient.invalidateQueries([activity.id, 'activity', 'pairings']);
    };

    const handlePairingRefresh = () => {
      console.log('activity-pairing-refresh');
      setShouldSetDefaultEventState(true);
      refetch();
      queryClient.invalidateQueries([activity.id, 'activity']);
      queryClient.invalidateQueries([activity.id, 'activity', 'pairings']);
    };

    pusherChannel.bind('refresh', handleRefresh);
    pusherChannel.bind('activity-pairing-refresh', handlePairingRefresh);

    return () => {
      pusherChannel.unbind('refresh', handleRefresh);
      pusherChannel.unbind('activity-pairing-refresh', handlePairingRefresh);
      pusher.unsubscribe(`activity-${activity.id}`);
    };
  }, [activity?.id, refetch, setShouldSetDefaultEventState, queryClient]);

  const activeGame = useMemo(() =>
    games?.find((game) => game.id === activity?.gameId),
    [games, activity]
  );

  const { data: activityData } = useQuery({
    queryKey: [activity?.id, 'activity'],
    queryFn: async () => {
      if (!activity?.id || !activity?.gameId) return null;

      try {
        const res = await webAxiosInstance.get(`/api/play/activities/${activity.id}`, {
          headers: {
            'Game-Id': activity.gameId,
          },
        });


        if (shouldSetDefaultEventState) {
          setDefault(res?.data?.data, eventData?.data, activePhasePod?.id);
        } else {
          setShouldSetDefaultEventState(true);
        }
        return res?.data?.data;
      } catch (e) {
        throw new Error(e);
      }
    },
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    enabled: Boolean(activity?.id && activity?.gameId)
  });

  const { data: registrationsRaw } = useSuspenseQuery(fetchEventRegistrationsQueryOptions({
    axiosInstance: webAxiosInstance,
    eventId,
    searchParams: { limit: 1000000 },
  }));

  const { playerRegistrations, nonPlayerRegistrations } = registrationsRaw?.data?.reduce((acc, registration) => {
    if (registration?.roles?.includes('general')) {
      acc.playerRegistrations.push(registration);
    }
    if (registration?.roles?.includes('judge') || registration?.roles?.includes('organizer') || registration?.roles?.includes('commentator')) {
      acc.nonPlayerRegistrations.push(registration);
    }
    return acc;
  }, { playerRegistrations: [], nonPlayerRegistrations: [] }) || {};


  const tabs = useMemo(() => {
    const allTabs = [
      {
        id: 'organize',
        name: 'Organize',
        component: <EventOrganizeTab setShouldSetDefaultEventState={setShouldSetDefaultEventState} />,
        show: eventData?.data?.status === 'inProgress' || Boolean(activity?.externalReference && Object.values(activity?.externalReference)?.length > 0)
      },
      {
        id: 'registrations',
        name: eventData?.data?.status === 'published' ? 'Registrations' : 'Roster',
        component: <EventRegistrationsTab activity={activity} registrations={playerRegistrations} />,
        show: eventData?.data?.status !== 'draft',
      },
      {
        id: 'standings',
        name: 'Standings',
        component: <EventStandingsTab activity={activity} registrations={playerRegistrations} />,
        show: ['complete', 'inProgress'].includes(eventData?.data?.status),
      },
      {
        id: 'manage',
        name: 'Manage',
        component: <EventDetailsTab activity={activity} event={eventData?.data} registrations={nonPlayerRegistrations} />,
        show: true,
      },
      {
        id: 'marketing',
        name: 'Marketing',
        component: <EventMarketingTab
          activity={activity}
          establishment={establishment}
          event={eventData?.data}
          game={activeGame}
        />,
        show: eventData?.data?.status === 'published',
      },
      {
        id: 'create',
        name: 'Finalize Draft',
        url: 'draft',
        show: eventData?.data?.status === 'draft',
      }
    ];

    return allTabs.filter((tab) => tab.show);
  }, [setShouldSetDefaultEventState, eventData?.data, activity, playerRegistrations, nonPlayerRegistrations, establishment, activeGame]);

  const [activeTab, setActiveTab] = useState(() => tabs[0]?.id);


  const activeTabContent = useMemo(() => {
    const currentTab = tabs.find((tab) => tab.id === activeTab);
    if (!currentTab?.show) {
      const firstValidTab = tabs.find(tab => tab.show);
      setActiveTab(firstValidTab?.id);
      return firstValidTab?.component;
    }
    return currentTab?.component;
  }, [tabs, activeTab, setActiveTab]);

  if (!establishment) return null;

  return (
    <main className="grid h-screen pt-[108px] pb-8 max-w-[1300px] mx-auto" style={{ minHeight: 'inherit' }}>
      <div className='rounded-xl bg-zinc-700/20 w-full h-full'>
        <Header
          activeTab={activeTab}
          activity={activityData}
          establishment={establishment}
          event={eventData?.data}
          game={activeGame}
          setActiveTab={setActiveTab}
          tabs={tabs}
        />
        <div className='px-8 py-4'>
          {activeTabContent}
        </div>
      </div>
    </main>
  );
}

export default SingleEventPage;
