type finalizePreviewMutationFnParams = {
  clientAxiosInstance: any;
  activityPhaseId: any;
};

export const finalizePreviewMutationFn = async ({
  clientAxiosInstance,
  activityPhaseId,
}: finalizePreviewMutationFnParams) => {
  return clientAxiosInstance.post(
    `/api/organize/activityPhases/${activityPhaseId}/finalizePreview`,
    {},
  );
};
