import EventCard from "@/components/Cards/Variants/EventCard";
import Loading from "@/components/Loading";
import Pagination from "@repo/ui/card/Pagination";

const EventList = ({
  events,
  isFetching,
  pagination,
  setPage,
  setLimit,
  limit,
  navigate
}) => {
  return (
    <>
      {isFetching ? (
        <Loading fullScreen />
      ) : (
        <>
          {events?.length > 0 ? (
            <ul role="list" className="divide-y divide-white/5">
              {events?.map((event) => (
                <EventCard key={event.id} event={event} />
              ))}
            </ul>
          ) : (
            <div className='flex justify-center items-center py-[100px]'>
              <p className='text-zinc-300'>No Events Found</p>
            </div>
          )}
          <Pagination
            data={pagination}
            setActivePage={(pageNum) => {
              setPage(pageNum)
              navigate({ search: (old) => ({ ...old, page: pageNum }) })
            }}
            setLimit={(e) => {
              setLimit(e)
              navigate({ search: (old) => ({ ...old, limit: +e }) })
            }}
            limit={limit}
          />
        </>
      )}
    </>
  );
};

export default EventList;
