import { useEffect, useState } from "react";
import dayjs from "dayjs";
import QRCode from "react-qr-code";

import { sortTicketPrices } from "@/components/Flows/CreateEvent/utils/ticketPrices";
import { getActivityPrizePool, getTicketPriceDisplay } from "@repo/utils/currency";

const textureMapping = {
  grunge: 'https://indieground.net/wp-content/uploads/2020/02/Freebie-DarkGrungeTextures-Preview-03.jpg'
}

const defaultSize = {
  width: 935,
  height: 1210
}

const scaleSize = (scale) => {
  if (scale < 0.2) return {
    flyer: {
      padding: '4',
      emptySpace: 'h-[100px]'
    },
    gameLogo: 'h-[80px] pt-4',
    eventName: 'text-xs',
    activityName: 'text-sm',
    day: 'text-xs',
    time: 'text-sm',
    entry: {
      container: 'h-[50px]',
      icon: '',
      fee: 'text-xs',
    },
    middle: {
      primaryText: 'text-3xl',
      secondaryText: '',
    },
    scanText: 'text-3xs',
    qrCode: 'w-[30%]',
    establishmentLogo: 'w-[30%]',
    cardeioLogo: 'w-[30%]',
  };

  if (scale < 0.4) return {
    flyer: {
      padding: '6',
      emptySpace: 'h-[150px]'
    },
    gameLogo: 'h-[100px] pt-6',
    eventName: 'text-sm',
    activityName: 'text-md',
    day: 'text-sm',
    time: 'text-md',
    entry: {
      container: 'h-[50px]',
      icon: '',
      fee: 'text-sm',
    },
    middle: {
      primaryText: 'text-[28px]',
      secondaryText: 'text-sm',
    },
    scanText: 'text-3xs',
    qrCode: 'w-[30%] pl-2',
    establishmentLogo: 'w-[30%]',
    cardeioLogo: 'w-[30%]',
  };

  if (scale < 0.6) return {
    flyer: {
      padding: '8',
      emptySpace: 'h-[200px]',
    },
    gameLogo: 'h-[120px] pt-8',
    eventName: 'text-md',
    activityName: 'text-lg',
    day: 'text-md',
    time: 'text-lg',
    entry: {
      container: 'h-[50px]',
      icon: '',
      fee: 'text-md',
    },
    middle: {
      primaryText: 'text-[32px]',
      secondaryText: '',
    },
    scanText: 'text-2xs',
    qrCode: 'w-[25%] pl-4',
    establishmentLogo: 'w-[35%] mb-[10%]',
    cardeioLogo: 'w-[25%]',
  };

  if (scale < 0.8) return {
    flyer: {
      padding: '10',
      emptySpace: 'h-[250px]',
    },
    gameLogo: 'h-[150px] pt-10',
    eventName: 'text-lg',
    activityName: 'text-lg',
    day: 'text-lg',
    time: 'text-xl',
    entry: {
      container: 'h-[60px]',
      icon: 'font-size-[48px]',
      fee: 'text-xl',
    },
    middle: {
      primaryText: 'text-[44px]',
      secondaryText: 'text-xl',
    },
    scanText: 'text-sm',
    qrCode: 'w-[22%] pl-4',
    establishmentLogo: 'w-[30%]',
    cardeioLogo: 'w-[22%]',
  };

  return {
    flyer: {
      padding: '12',
      emptySpace: 'h-[300px]',
    },
    gameLogo: 'h-[180px] pt-12',
    eventName: 'text-[28px] mb-2 mt-8',
    activityName: 'text-[40px] -mt-2',
    day: 'text-[24px] mb-1 mt-8',
    time: 'text-[28px] mb-8 -mt-2',
    entry: {
      container: 'h-[60px]',
      icon: 'text-2xl -mt-1 opacity-70',
      fee: 'text-2xl',
    },
    middle: {
      primaryText: 'text-[56px]',
      secondaryText: 'text-3xl',
    },
    scanText: 'text-lg',
    qrCode: 'w-[22%] pl-8',
    establishmentLogo: 'w-[30%] mb-[5%]',
    cardeioLogo: 'w-[22%]',
  };
}

const PaperVerticalFlyer = ({
  activity,
  customization = {
    overlay: {
      color: '#18181B',
      opacity: 80,
      blend: null,
    },
    texture: 'none',
    primaryText: '',
    secondaryText: '',
  },
  establishment,
  event,
  game,
  printRef,
  scale = 1,
}) => {
  const ticketPrice = activity?.ticketPrices?.sort(sortTicketPrices)?.[0];
  const prizePool = getActivityPrizePool(activity);
  const height = defaultSize.height * scale;
  const width = defaultSize.width * scale;
  const [backgroundImage, setBackgroundImage] = useState('');
  const [textureImage, setTextureImage] = useState('');
  const [cardeioImage, setCardeioImage] = useState('');
  const [establishmentImage, setEstablishmentImage] = useState('');
  const [gameImage, setGameImage] = useState('');

  let environment = "production";
  const origin = location.origin;
  if (
    origin.includes("localhost:") ||
    origin.includes(".dev.") ||
    origin.includes("dev.")
  ) {
    environment = "development";
  }
  const gameUrl = game?.settings
    ?.find((setting) => setting.settingKey?.key === 'gameUrls')
    ?.value
    ?.find((v) => v.environment === environment)
    ?.url;
  const returnTo = (window.location.href?.includes('dashboard.carde.io') ? gameUrl : window.location.href) || window.location.href;

  useEffect(() => {
    // Proxy the image via the local server
    const fetchProxiedImage = async (imageUrl, setterFn) => {
      const response = await fetch(`${import.meta.env.VITE_PROXY_SERVER_URL}/proxy?url=${encodeURIComponent(imageUrl)}`);
      const blob = await response.blob();
      const src = URL.createObjectURL(blob);
      setterFn(src);
    };

    fetchProxiedImage(event.bannerImage, setBackgroundImage);
    fetchProxiedImage(textureMapping[customization?.texture], setTextureImage);
    fetchProxiedImage('https://storage.googleapis.com/cardeio-images/cardeio/Cardeio-Logo-2024.webp', setCardeioImage);
    if (establishment?.logoUrl) {
      fetchProxiedImage(establishment.logoUrl, setEstablishmentImage);
    }
    const gameLogo = game?.resourceImages?.find(img => img.imageType === 'logo')?.image?.url;
    fetchProxiedImage(gameLogo, setGameImage);
  }, [customization?.texture, establishment.logoUrl, event.bannerImage, game?.resourceImages]);


  return (
    <div className={`relative${printRef ? ' pointer-events-none' : ''}`} style={{  height: `${height}px`, width: `${width}px` }} ref={printRef}>
      {backgroundImage && (
        <div
          className='absolute bg-zinc-900 top-0 left-0 w-full h-full'
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundPositionX: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            maskImage: `linear-gradient(rgb(0, 0, 0) 80%, transparent 100%)`
          }}
        />
      )}

      {textureImage && (
        <div
          className='absolute top-0 left-0 z-10 w-full h-full opacity-40'
          style={{
            backgroundImage: `url(${textureImage})`,
            backgroundPositionX: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        />
      )}

      {customization?.overlay?.color && (
        <div
          className={`absolute top-0 left-0 z-20 w-full h-full`}
          style={{
            backgroundColor: customization?.overlay?.color,
            opacity: customization?.overlay?.opacity / 100
          }}
        />
      )}

      <div className='absolute top-0 left-0 z-30 h-full w-full'>
        <div className='flex flex-col items-center justify-between h-full'>
          <div className='flex flex-col items-center w-full' style={{ textShadow: '1px 1px 2px black' }}>
            <div className={`${scaleSize(scale).gameLogo} flex items-center justify-center w-full pb-4`}>
              {gameImage && <img src={gameImage} className='h-full' />}
            </div>
            <div className='flex flex-col items-center w-full bg-zinc-900/50 py-4'>
              <h4 className={`${scaleSize(scale)?.eventName} font-semibold w-[90%] text-center uppercase`} style={{ fontFamily: '"Bebas Neue", sans-serif' }}>{event.name}</h4>
              <h2 className={`${scaleSize(scale)?.activityName} text-xl font-bold w-[90%] text-center mb-2 uppercase tracking-[1px]`}>{activity.name}</h2>
              <h4 className={`${scaleSize(scale)?.day} text-md font-semibold w-[90%] text-center uppercase tracking-[1px]`} style={{ fontFamily: '"Bebas Neue", sans-serif' }}>{dayjs(activity?.startsAt).format('dddd MMMM D')}</h4>
              <h3 className={`${scaleSize(scale)?.time} text-lg font-light w-[90%] text-center uppercase -mt-2`}>{dayjs(activity?.startsAt).format('h:mmA z')}</h3>
            </div>
          </div>

          {(prizePool > 0) || customization.primaryText || customization.secondaryText ? (
            <div className={`px-${scaleSize(scale).flyer.padding} flex flex-col items-center my-6`} style={{ textShadow: '1px 1px 2px black' }}>
              <h2 className={`${scaleSize(scale).middle.primaryText} font-semibold text-center uppercase`} style={{ fontFamily: '"Bebas Neue", sans-serif' }}>{customization.primaryText || getTicketPriceDisplay({ amount: prizePool * 100, currency: ticketPrice?.currency, ignoreDecimal: true })}</h2>
              <h4 className={`${scaleSize(scale).middle.secondaryText} font-bold w-[90%] text-center uppercase -mt-1`}>{customization.secondaryText || 'Prize Pool'}</h4>
            </div>
          ) : (
            <div className={scaleSize(scale).flyer.emptySpace} />
          )}

          <div className={`flex items-center justify-center gap-4 ${scaleSize(scale).entry.container}`} style={{ textShadow: '1px 1px 2px black' }}>
            <div className={scaleSize(scale).entry.icon}>╼</div>
            <h4 className={`${scaleSize(scale)?.entry.fee} font-semibold w-[90%] text-center uppercase`} style={{ fontFamily: '"Bebas Neue", sans-serif' }}>{ticketPrice?.amount ? getTicketPriceDisplay(ticketPrice) : 'No'} Entry Fee</h4>
            <div className={scaleSize(scale).entry.icon}>╾</div>
          </div>

          <div className={`flex items-end justify-between h-[25%] w-full p-${scaleSize(scale).flyer.padding}`}>
            {cardeioImage && <img src={cardeioImage} className={`${scaleSize(scale).cardeioLogo}`} />}
            <div className={`${scaleSize(scale).establishmentLogo} relative h-full`}>
              <div className='absolute top-0 left-0 p-4 w-full h-full'>
                {establishmentImage && <img src={establishmentImage} className='w-full' />}
              </div>
            </div>
            <div className={`${scaleSize(scale).qrCode}`}>
              <div className='flex flex-col items-center bg-zinc-100 px-1 py-2 rounded-lg'>
                <QRCode className='h-[85%] w-[85%]'
                  value={`${import.meta.env.VITE_WEB_URL}/redirect?params=path||to:${import.meta.env.VITE_WEB_URL}/checkout/events/${event.id},query||returnTo:${returnTo},query||origin:direct,query||source:printoutFlyerQrCode`} />
                <p className={`${scaleSize(scale).scanText} mt-2 text-zinc-900 font-bold text-center uppercase tracking-[-1px]`}>Scan to Join</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaperVerticalFlyer;
