import { Command } from 'cmdk';
import { Check } from 'lucide-react';
import { useState, useEffect } from 'react';
import { Popover, PopoverAnchor, PopoverContent } from '@repo/ui/popover';
import { Input } from '@repo/ui/input';
import { cn } from '../../utils';

export default function Autocomplete({
  selectedValue,
  onSelectedValueChange,
  searchValue,
  onSearchValueChange,
  defaultValue,
  items = [],
  isLoading,
  emptyMessage = "No items found.",
  placeholder = "Search...",
  searchOnFocus = true,
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (defaultValue && items.length > 0) {
      const defaultItem = items.find(item => item.value === defaultValue);
      if (defaultItem) {
        onSelectedValueChange(defaultItem.value);
        onSearchValueChange(defaultItem.label);
      }
    }
  }, [defaultValue, items]);

  const reset = () => {
    onSelectedValueChange("");
    onSearchValueChange("");
  };

  const onInputBlur = () => {
    setOpen(false);
  };

  const onSelectItem = (inputValue) => {
    if (inputValue === selectedValue) {
      reset();
    } else {
      onSelectedValueChange(inputValue);
    }
    setOpen(false);
  };

  return (
    <div className="flex items-center">
      <Popover open={open} onOpenChange={setOpen}>
        <Command shouldFilter={false}>
          <PopoverAnchor asChild>
            <Command.Input
              asChild
              value={searchValue}
              onValueChange={onSearchValueChange}
              onKeyDown={(e) => setOpen(e.key !== "Escape")}
              onMouseDown={() => setOpen((open) => !!searchValue || !open)}
              onFocus={() => {
                if (searchOnFocus) {
                  onSearchValueChange(searchValue);
                  setOpen(true);
                }
              }}
              onBlur={onInputBlur}
            >
              <Input placeholder={placeholder} />
            </Command.Input>
          </PopoverAnchor>

          {!open && <Command.List aria-hidden="true" className="hidden" />}

          <PopoverContent
            asChild
            onOpenAutoFocus={(e) => e.preventDefault()}
            onInteractOutside={(e) => {
              if (
                e.target instanceof Element &&
                e.target.hasAttribute("cmdk-input")
              ) {
                e.preventDefault();
              }
            }}
            className={cn(
              "z-50 min-w-[8rem] overflow-hidden rounded-md border bg-primary p-1 text-popover-foreground shadow-md",
              "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
              "w-[--radix-popover-trigger-width] p-0"
            )}
          >
            <Command.List>
              {isLoading && (
                <Command.Loading>
                  <div className="p-1">
                    <div className={"animate-pulse rounded-md bg-muted h-6 w-full"} />
                  </div>
                </Command.Loading>
              )}

              {items.length > 0 && !isLoading ? (
                <Command.Group>
                  {items.map((option, index) => (
                    <div key={option.value}>
                      <Command.Item
                        key={index}
                        value={option.value}
                        onMouseDown={(e) => e.preventDefault()}
                        onSelect={onSelectItem}
                        className={cn(
                          "relative flex cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors",
                          "hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
                        )}
                      >
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            selectedValue === option.value
                              ? "opacity-100"
                              : "opacity-0"
                          )}
                        />
                        {option.label}
                      </Command.Item>

                    </div>
                  ))}
                </Command.Group>
              ) : null}

              {!isLoading && searchValue ? (
                <Command.Empty>{emptyMessage}</Command.Empty>
              ) : null}
            </Command.List>
          </PopoverContent>
        </Command>
      </Popover>
    </div>
  );
}
