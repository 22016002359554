import { Button } from "@repo/ui/button";
import AppliedForGames from "@/components/Games/AppliedForGames";
import AvailableGames from "@/components/Games/AvailableGames";
import { useGameStore } from "@/store/useGameStore";
import { establishmentTools } from "@/_pages_/auth/Entity/Store/Tools/toolsConfig";
import ToolCard from "@/components/Card/Tool";
import { queryClient } from "@/lib/queryClient";
import { useState } from "react";

const StoreLandingPage = ({ entityId, userData }) => {
	const { appliedGames, hasActiveSearch } = useGameStore();
	const [showAllGames, setShowAllGames] = useState(false)
	return (
		<main className="min-h-screen px-6 pb-16 pt-[108px] lg:px-12">
			<div className='flex justify-between items-center mb-2 mt-7'>
				<h1 className="text-4xl font-extrabold tracking-tight text-zinc-300">
					Welcome, {userData?.user?.firstName}!
				</h1>
				<Button
					variant="outline"
					onClick={() => {
						queryClient.invalidateQueries();
					}}
					className="text-sm"
				>
					Refresh
				</Button>
			</div>

      {/*<EstablishmentStats entityId={entityId} />*/}

      <div className="flex flex-wrap gap-12 py-8">
        {establishmentTools(entityId)
          .filter((tool) => !tool.isComingSoon)
          .map((tool, idx) => (
            <ToolCard key={idx} tool={tool} />
          ))}
      </div>

			{hasActiveSearch && appliedGames?.length !== 0 ? null : (
				<div className="flex flex-col justify-between mt-16">
					<div className='flex justify-between items-center mb-8'>
						<h3 className="text-3xl font-extrabold tracking-tight text-zinc-300">My Games</h3>
					</div>
					<AppliedForGames />

					<div className='mt-8 flex justify-between items-center mb-8'>
						<h3 className="text-3xl font-extrabold tracking-tight text-zinc-300">All Games in Carde.io</h3>

						<Button variant="ghost" size="sm" onClick={() => setShowAllGames(!showAllGames)}>	
							{showAllGames ? "Hide All Games" : "Show All Games"}
						</Button>
					</div>
					<div className={`${showAllGames ? "block" : "hidden"}`}>
						<AvailableGames />
					</div>
				</div>
			)}

      {hasActiveSearch && (
        <div className="flex flex-col justify-between mt-32">
          <div className="flex justify-between items-center mb-8">
            <h3 className="text-3xl font-extrabold tracking-tight text-zinc-300">
              Other Games
            </h3>
          </div>
          <AvailableGames />
        </div>
      )}
    </main>
  );
};

export default StoreLandingPage;
