import React from "react";

import { Avatar, AvatarImage } from "@repo/ui/avatar";
import { Button } from "@repo/ui/button";
import { RiSwap2Fill } from "@remixicon/react";
import { Switch } from "@repo/ui/switch";
import {
  Select,
  SelectContent,
  SelectTextItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/select";

const PlayerSection = ({
  isPreview = false,
  dropPlayer,
  isLoading = false,
  isSwapping = false,
  player,
  resultSubmitted,
  roster = [],
  setDropPlayer,
  setPlayerToSwap,
  setUpdatedPlayer,
  updatedPlayer,
}) => {
  console.log({ player, roster })
  return (
    <div className='flex flex-col items-center justify-center w-[50%]'>
      <Avatar className={`h-24 w-24 border-zinc-950/50 border-[1px]`}>
        <AvatarImage src={player?.gameUser?.imageUrl} />
      </Avatar>
      <p className='text-lg font-bold'>{player?.user?.displayName}</p>

      {setPlayerToSwap && !resultSubmitted && (
        <Button
          className='flex items-center mt-4'
          onClick={() => setPlayerToSwap(player)}
          size='sm'
          variant='gradient-contained'
        >
          <RiSwap2Fill className='h-4 w-4' />
          <p className='ml-1'>Swap</p>
        </Button>
      )}

      {!isPreview && setDropPlayer ? (
        <div className='flex items-center mt-4'>
          <p className='text-sm mr-2'>Drop?</p>
          <Switch checked={dropPlayer} className='h-3 w-8' onCheckedChange={() => setDropPlayer(!dropPlayer)} />
        </div>
      ) : null}

      {isSwapping && (
        <div className='flex flex-col items-center mt-4'>
          <p className='text-xs font-bold uppercase mb-1'>Swapping with:</p>
          <Select
            onValueChange={setUpdatedPlayer}
            value={updatedPlayer}
            disabled={isLoading}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select a Player" />
            </SelectTrigger>
            <SelectContent>
              {roster.map(player => (
                <SelectTextItem key={player.id} value={player.id}>
                  <div className='flex items-center'>
                    <Avatar className={`h-8 w-8 border-zinc-950/50 border-[1px] mr-2`}>
                      <AvatarImage src={player?.rawPlayer?.gameUser?.imageUrl} />
                    </Avatar>
                    <div className='flex flex-col'>
                      <p>{player.label}</p>
                    </div>
                  </div>
                </SelectTextItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      )}
    </div>
  );
};

export default PlayerSection;
