import { useEffect, useState } from "react";

import { useEventStore } from "@/store/useEventStore";
import PhaseDropdown from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Organize/PhaseDropdown";
import PodSwitcher from "../../PodSwitcher";

const OrganizeHeaderNavigation = ({ showPodSwitcher = true }) => {
  const { activePhasePod, allPhases, activePhases } = useEventStore();
  const [activeStage, setActiveStage] = useState(activePhasePod?.stage);

  const stages = [];
  allPhases?.forEach(phase => {
    stages[phase?.stage - 1] ? stages[phase?.stage - 1].push(phase) : stages[phase?.stage - 1] = [phase];
  })

  useEffect(() => {
    setActiveStage(activePhasePod.stage);
  }, [activePhasePod.stage])

  if (stages?.length === 0) { return null }
  return (
    <div className='w-full p-4 bg-zinc-950/90 rounded-lg flex flex-row items-center justify-between'>
      <div className="flex flex-row gap-4">
        {stages?.length > 0 && stages.map((phases, index) => (
          <>
            <PhaseDropdown activeStage={activeStage} phases={phases} stageNumber={index + 1} />
            {stages?.length !== 1 && index !== stages.length - 1 && (
              <span>→</span>
            )}
          </>
        ))}
      </div>
      <div className="">
        {showPodSwitcher && activePhasePod?.activityPhaseType === "tournament" && activePhases?.length > 1 && <PodSwitcher activePhases={activePhases} activePhase={activePhasePod} />}
      </div>
    </div>
  )
}

export default OrganizeHeaderNavigation;
