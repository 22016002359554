import { eventQueryOptions } from '@/data/organization/queries';
import { useEventStore } from '@/store/useEventStore';
import { useRootStore } from '@/store/useRootStore';
import { webAxiosInstance } from '@/utils/axios';
import { cn } from '@/utils/cn';
import { useQuery } from '@tanstack/react-query';
import { createFileRoute, Link } from '@tanstack/react-router'
import dayjs from 'dayjs';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const TimeBox = ({ children, ...props }) => {
  return (
    <span className={cn('flex items-center justify-center font-bold text-green-700/80', props.className)} {...props}>
      {children}
    </span>
  )
}

const IndividualTimer = ({ timer, isOnTimerPage = false }) => {
  const endTime = timer.endTime;
  const Ref = useRef(null);
  const containerRef = useRef(null);
  const timerDigitsRef = useRef(null);
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");

  const getTimeRemaining = (e) => {
    const total =
      Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor(
      (total / 1000 / 60) % 60
    );
    const hours = Math.floor(
      (total / 1000 / 60 / 60) % 24
    );
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = useCallback((e) => {
    let { total, hours, minutes, seconds } =
      getTimeRemaining(e);
    if (total >= 0) {
      setTimerHours(hours > 9 ? hours : "0" + hours);
      setTimerMinutes(minutes > 9 ? minutes : "0" + minutes);
      setTimerSeconds(seconds > 9 ? seconds : "0" + seconds);
    }
  }, []);

  const clearTimer = useCallback((e) => {

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  }, [startTimer]);

  const getDeadTime = useCallback(() => {
    let deadline = new Date(endTime);

    // This is where you need to adjust if
    // you entend to add more time
    return deadline;
  }, [endTime]);

  useEffect(() => {
    clearTimer(getDeadTime());
  }, [clearTimer, getDeadTime]);

  return <div ref={containerRef} className='text-base border border-zinc-100/10 rounded-lg p-4 w-full h-full flex flex-col items-center justify-center'>
    <div className='flex flex-col gap-2 items-center h-1/4'>
      <div className='text-center text-lg'>
        {timer.name} - Pod: {timer.pod}
      </div>
    </div>
    {Number(timerHours) === 0 && Number(timerMinutes) === 0 && Number(timerSeconds) === 0 ? (
      <div className='flex flex-col items-center justify-center h-1/2'>
        <div className='tracking-[3px] text-lg'>
          TIMER
        </div>
        <div className='tracking-[1px] text-lg'>
          COMPLETE
        </div>
      </div>
    ) : (
      <div className='flex flex-col items-center justify-center h-1/2'>
        <div ref={timerDigitsRef} className='flex items-center justify-between gap-1'>
          {Number(timerHours) > 0 && (
            <>
              <div className='text-zinc-100/80 lg:text-4xl'>{timerHours}</div>
              <div className='text-zinc-100/80 lg:text-4xl'>:</div>
            </>
          )}
          <div className='text-zinc-100/80 lg:text-4xl'>{timerMinutes}</div>
          <div className='text-zinc-100/80 lg:text-4xl'>:</div>
          <div className='text-zinc-100/80 lg:text-4xl'>{timerSeconds}</div>
        </div>
        <p className='tracking-[2px] uppercase text-lg'>Remaining</p>
      </div>
    )}
  </div>
}

const TimerPage = () => {
  const { setDefault, setShouldSetDefaultEventState, shouldSetDefaultEventState, refetch, activePhasePod } = useEventStore();
  const { userData } = useRootStore();

  const { activityId, entityId, eventId } = Route.useParams();

  const establishment = useMemo(() => {
    const found = userData?.establishments?.find((est) => est.entityId === entityId);
    return found || null;
  }, [userData, entityId]);

  const { data: eventData } = useQuery(eventQueryOptions(eventId));

  const activity = eventData?.data?.activities?.[0];
  const { data: activityData } = useQuery({
    queryKey: [activity?.id, 'activity'],
    queryFn: async () => {

      if (!activity?.id || !activity?.gameId) return null;

      try {
        const res = await webAxiosInstance.get(`/api/play/activities/${activity.id}`, {
          headers: {
            'Game-Id': activity.gameId,
          },
        });


        if (shouldSetDefaultEventState) {
          setDefault(res?.data?.data, eventData?.data, activePhasePod?.id);
        } else {
          setShouldSetDefaultEventState(true);
        }
        return res?.data?.data;
      } catch (e) {
        throw new Error(e);
      }
    },
    enabled: Boolean(activity?.id && activity?.gameId)
  });

  const timers = _.reduce(activityData?.phases, (timers, aps) => {
    if (aps?.[0]?.status !== 'inProgress') return timers;
    if (['draft', 'deckbuilding'].includes(aps?.[0]?.activityPhaseType)) {
      timers.push({
        name: aps?.[0].name,
        stage: aps?.[0].stage,
        activityPhaseType: aps?.[0].activityPhaseType,
        duration: aps?.[0].configuration.duration,
        startTime: aps?.[0].startTime,
        endTime: dayjs(aps?.[0].startTime).add(aps?.[0].configuration.duration, 'minutes'),
      })
    }
    if (aps?.[0]?.activityPhaseType === 'tournament') {
      for (const ap of aps) {
        const currentRound = ap.tournament.rounds.find(tr => {
          return tr.roundNumber === ap.tournament.currentRound
        })
        timers.push({
          name: ap.name,
          stage: ap.stage,
          pod: ap.pod,
          activityPhaseType: ap.activityPhaseType,
          roundNumber: currentRound.roundNumber,
          duration: ap.configuration.duration,
          startTime: currentRound.startTime,
          endTime: currentRound.endTime,
        })
      }
    }

    return timers
  }, [])


  return <div className='flex flex-col gap-4 mt-24'>
    <div className='flex gap-2'>
      <div className='ml-2'>
        <Link href={`/entity/${entityId}/tools/events/${eventId}`}> Back to Event</Link>
      </div>
      <div className='flex-1 text-center'>
        {eventData.data.name} - {activity.name} - Stage: {timers?.[0]?.stage}
      </div>
    </div>
    <div className='flex flex-col items-center justify-center h-[calc(100vh-200px)]'>
      <div className={cn('p-8 w-full h-full grid grid-cols-4 gap-4 auto-rows-fr')}>
        {timers.map((timer) => {
          return <IndividualTimer key={timer.name} timer={timer} isOnTimerPage />
        })}
      </div>
    </div>
  </div>
}

export const Route = createFileRoute('/_authenticated/entity/$entityId/tools/events/$eventId/activity/$activityId/timer/')({
  component: TimerPage
})
