export const VIEW_TYPES = {
  CALENDAR: 'calendar',
  LIST: 'list'
};

export const TAB_TYPES = {
  UPCOMING: 'upcoming',
  COMPLETE: 'complete'
};

export const SEARCH_PARAMS = {
  OWNER_IDS: 'ownerIds',
  LIMIT: 'limit',
  NAME: 'name',
  PAGE: 'page',
  STATUS: 'statusInclude',
  GAME_IDS: 'gameIds',
  STARTS_AT: 'startsAt',
  ENDS_AT: 'endsAt'
};

export const EVENT_STATUS = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  IN_PROGRESS: 'inProgress',
  CANCELED: 'canceled',
  COMPLETE: 'complete'
}; 