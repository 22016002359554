import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";
import { useEventStore } from "@/store/useEventStore";
import PairingCountdownTimer
  from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Organize/Pairings/PairingCountdownTimer";
import { startRoundTimerMutationFn } from "@repo/api/base";
import { webAxiosInstance } from "@/utils/axios";
import { getCurrentFormattedDateTime } from "@repo/utils/date";
import OrganizeActions from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Organize/OrganizeActions";
import PodSwitcher from "../../../PodSwitcher";
import { Link } from "@tanstack/react-router";

const PairingsTableHeader = ({ refetchPairings, activePhasePod, activePhases = [] }) => {
  const { currentTournament, currentTournamentRound, refetch, activity } = useEventStore();

  const startTimerMutation = useMutation({
    mutationFn: startRoundTimerMutationFn,
    onSuccess: () => {
      refetch();
      toast('Successfully Started Round Timer!');
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: "There was an error with starting the round timer. Please reach out to support. We look forward to hearing from you!",
        action: {
          label: "Contact Support",
          onClick: () => window.location.href = 'https://carde.io/support',
        },
      })
    },
  });

  const handleStartNow = () => {
    startTimerMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      tournamentRoundId: currentTournamentRound.id,
      startTime: getCurrentFormattedDateTime(),
    });
  }

  const isPreview = activePhasePod.status === 'preview';

  return (
    <div className="rounded-t-xl px-4 py-5 sm:px-6 bg-zinc-950/60 w-full">
      <div className="-ml-4 -mt-2 flex flex-wrap items-start justify-between sm:flex-nowrap">

        <div className="flex self-stretch flex-col items-start justify-between ml-4">
          <div>
            <h3 className="text-2xl font-semibold leading-6 text-zinc-100">{`Pod ${activePhasePod?.pod} - Round ${activePhasePod?.tournament?.rounds[activePhasePod?.tournament?.currentRound - 1]?.roundNumber} Pairings${isPreview ? ' Preview' : ''}`}</h3>
          </div>
          {/* TEMP REMOVE POD SWITCHER */}
          {/* {activePhases?.length > 1 ? <div className="mt-6"><PodSwitcher activePhases={activePhases} activePhase={activePhasePod} /></div> : null} */}
        </div>


        <div className='flex flex-col items-center justify-center gap-4'>
          {!isPreview && currentTournament?.currentRound === currentTournamentRound?.roundNumber && (
            <>
              {currentTournamentRound?.endTime ? (
                <>
                  <PairingCountdownTimer id={currentTournamentRound?.id} endTime={currentTournamentRound?.endTime} />
                  <Link to={`${window.location.pathname}/activity/${activity?.id}/timer`}>Open FullScreen Timer</Link>
                </>
              ) : (
                <button
                  className={`flex flex-col gap-1 items-center justify-center border-2 border-zinc-100/80 p-4 rounded-lg`}
                  onClick={handleStartNow}
                >
                  <div className='text-sm tracking-[1px]'>
                    START
                  </div>
                  <div className='text-sm tracking-[2px]'>
                    TIMER
                  </div>
                </button>
              )}
            </>
          )}
          {currentTournament?.currentRound === currentTournamentRound?.roundNumber && (
            <OrganizeActions refetchPairings={refetchPairings} />
          )}
        </div>
      </div>
    </div>
  )
};

export default PairingsTableHeader;
