import { create } from 'zustand';

export const useEventsSearchStore = create((set) => ({
  games: [],
  searchText: '',
  page: 1,
  limit: 50,
  setGames: (games) => set({ games }),
  setSearchText: (searchText) => set({ searchText }),
  setPage: (page) => set({ page }),
  setLimit: (limit) => set({ limit }),
  reset: () => set({ games: [], searchText: '', page: 1 }),
}));
