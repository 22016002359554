import { useState, useCallback, useMemo } from "react";
import dayjs from "dayjs";
import advancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Link } from "@tanstack/react-router";
import { BanknotesIcon, EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { RiFileCopyLine, RiVipDiamondFill } from '@remixicon/react';
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

import { Popover, PopoverContent, PopoverTrigger } from "@repo/ui/popover";
import { useGameStore } from "@/store/useGameStore";
import { Modal, ModalTrigger } from "@repo/ui/modal";
import CopyEventModal from "@/components/Modal/Event/CopyEvent";
import { sortTicketPrices } from "@/components/Flows/CreateEvent/utils/ticketPrices";
import { getTicketPriceDisplay } from "@repo/utils/currency";
import CancelEventModalContent from "@/components/Modal/Event/CancelEvent";

dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(utc);

const statuses = {
  draft: 'text-gray-500 bg-gray-100/10 border-gray-100/20',
  published: 'text-blue-400 bg-blue-400/10 border-blue-400/20',
  inProgress: 'text-yellow-500 bg-yellow-500/10 border-yellow-500/20',
  complete: 'text-green-600 bg-green-500/10 border-green-500/20',
  canceled: 'text-red-700 bg-rose-700/10 border-red-700/20',
}

const EventCard = ({ event }) => {
  const classNames = (...classes) => classes.filter(Boolean).join(' ');

  const [isCopyEventModalOpen, setIsCopyEventModalOpen] = useState(false);
  const [isCancelEventModalOpen, setIsCancelEventModalOpen] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handlePopoverChange = useCallback((open) => {
    setIsPopoverOpen(open);
  }, []);

  const handleCopyModalChange = useCallback((open) => {
    setIsCopyEventModalOpen(open);
  }, []);

  const handleCancelModalChange = useCallback((open) => {
    setIsCancelEventModalOpen(open);
  }, []);

  const { activity, ticketPrice, } = useMemo(() => {
    const activity = event?.activities?.[0];
    const activeGame = useGameStore.getState().games?.find((game) => game.id === activity?.gameId);
    const ticketPrice = activity?.ticketPrices?.sort(sortTicketPrices)?.[0];

    const regCount = new Array(activity?.registrationCount);
    const loopAmount = activity?.registrationCount > 4 ? 5 : activity?.registrationCount;

    for (let i = 0; i < loopAmount; i++) {
      regCount[i] = i + 1;
    }

    return { activity, activeGame, ticketPrice, regCount, loopAmount };
  }, [event]);

  const LinkWrapper = useCallback(({ children }) => {
    if (isCopyEventModalOpen) {
      return <div>{children}</div>;
    }
    return <Link to={event.id}>{children}</Link>;
  }, [isCopyEventModalOpen, event.id]);

  return (
    <LinkWrapper>
      <li className="relative group flex items-center space-x-4 px-4 py-4 sm:px-6 lg:px-8 hover:bg-zinc-800/50 border-y-[2px] border-zinc-400/20 overflow-hidden">
        {event.startsAt && (
          <div className='flex flex-col items-center justify-center p-4 rounded-lg bg-zinc-500/5'>
            <p className='text-2xs uppercase tracking-widest'>{dayjs(event.startsAt).format('MMM')}</p>
            <p className='text-2xl font-bold'>{dayjs(event.startsAt).format('DD')}</p>
            <p className='text-2xs uppercase tracking-widest'>{dayjs(event.startsAt).format('YYYY')}</p>
          </div>
        )}
        <div className="min-w-0 flex-auto">
          <div className="flex items-center gap-x-3">
            <div className='flex flex-col gap-4'>
              <div className='flex items-center gap-2'>
                <div className={classNames(statuses[event.status], 'flex-none rounded-full p-1')}>
                  <div className="h-2 w-2 rounded-full bg-current" />
                </div>
                <p className={classNames(statuses[event.status], 'border-[1px] flex-none rounded-full px-[8px] uppercase text-2xs')}>{event.status}</p>
                {(event?.featured || activity?.featured) && <RiVipDiamondFill className='h-4 w-4 text-yellow-400' />}
                {ticketPrice?.amount > 0 && (
                  <>
                    <BanknotesIcon className='h-4 w-4 text-green-600' />
                  </>
                )}
                {ticketPrice && <span className={`text-2xs font-bold px-2 rounded-lg bg-zinc-600/20 ${ticketPrice?.amount > 0 ? 'text-green-600 border-[1px] border-green-600/30' : ''}`}>{getTicketPriceDisplay(ticketPrice)}</span>}
              </div>
              <h2 className="min-w-0 text-sm font-semibold leading-6 text-white">
                <span className="text-xl font-bold truncate">{event.name}</span>
                <span className="absolute inset-0" />
              </h2>
            </div>
          </div>
          <div className="mt-3 flex items-center gap-x-2.5 text-xs leading-5 text-gray-400">
            <p className="whitespace-nowrap">{event.startsAt ? `${dayjs(event.startsAt).format('ddd. h:mm A z')}` : 'No Start Date'}</p>
            {activity?.name && (
              <>
                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 flex-none fill-gray-300">
                  <circle cx={1} cy={1} r={1} />
                </svg>
                <p className="whitespace-nowrap">{activity?.name}</p>
              </>
            )}
          </div>
        </div>

        {activity?.capacity && (
          <div className={`flex flex-col items-end z-10`}>
            <div className='flex items-center'>
              {/* {activity?.registrationCount > 0 ? (
                <>
                  {regCount?.map((index) => (
                    <Avatar
                      key={`reg-${index}`}
                      className={`h-8 w-8 border-zinc-950/50 border-[1px] ${(activity?.registrationCount >= 2 && index > 0) ? 'ml-[-8px]' : ''}`}
                    >
                      <AvatarImage src={`https://storage.googleapis.com/cardeio-images/defaults/${activeGame?.slug}/profile/${Math.floor(Math.random() * 15) + 1}.webp`} />
                    </Avatar>
                  ))}
                </>
              ) : null} */}

              {/* {activity?.registrationCount > 5 && (
                <p className='text-2xs ml-2'>+ {activity?.registrationCount - 5} More</p>
              )} */}
            </div>
            <div className='relative px-4 py-1 bg-zinc-800/80 rounded-full z-10'>
              <div className={`absolute left-0 top-0 h-full rounded-l-full z-20 bg-secondary/60 ${activity?.registrationCount >= activity?.capacity ? 'rounded-full' : ''}`} style={{ width: activity?.registrationCount > activity?.capacity ? '100%' : `${(activity?.registrationCount || 0) / (activity?.capacity) * 100}%` }} />
              <p className="text-2xs uppercase whitespace-nowrap z-30">{activity?.registrationCount > 0 ? `${activity?.registrationCount || 0} / ${activity?.capacity} Capacity` : 'No Registrations Yet'}</p>
            </div>
          </div>
        )}

        <img src={activity?.gameLogos?.[0]} className='w-[100px] z-10' />
        <div className='z-20'>
          <Popover open={isPopoverOpen} onOpenChange={handlePopoverChange}>
            <PopoverTrigger asChild>
              <button className='pointer rounded-3xl p-2 hover:bg-zinc-200/10' onClick={(e) => { e.preventDefault(); handlePopoverChange(!isPopoverOpen) }}>
                <EllipsisVerticalIcon className="h-5 w-5 flex-none text-zinc-200" aria-hidden="true" />
              </button>
            </PopoverTrigger>
            <PopoverContent className="bg-zinc-950 border-zinc-200/10 w-fit" align='end'>
              <div className='flex flex-col gap-2'>
                <Modal open={isCopyEventModalOpen} onOpenChange={handleCopyModalChange}>
                  <ModalTrigger>
                    <button className='py-2 px-4 hover:bg-zinc-900' onClick={(e) => { e.preventDefault(); handleCopyModalChange(!isCopyEventModalOpen) }}>
                      <div className='flex items-center justify-center'>
                        <RiFileCopyLine className="h-4 w-4 mr-1" aria-hidden="true" />
                        <p className='text-sm'>
                          Copy Event
                        </p>
                      </div>
                    </button>
                  </ModalTrigger>
                  <CopyEventModal handleClose={() => handleCopyModalChange(false)} event={event} />
                </Modal>
                {event.status !== 'complete' &&
                  <div className="flex-shrink-0 h-full mt-2">
                    <Modal open={isCancelEventModalOpen} onOpenChange={handleCancelModalChange}>
                      <ModalTrigger asChild>
                        <button className='py-2 px-4 hover:bg-zinc-900' onClick={(e) => { e.preventDefault(); handleCancelModalChange(!isCancelEventModalOpen) }}>
                          <div className='flex items-center justify-center'>
                            <ExclamationCircleIcon className="h-4 w-4 text-red-500 mr-1" aria-hidden="true" />
                            <span className='text-sm text-red-600'>Cancel Event</span>

                          </div>
                        </button>
                      </ModalTrigger>
                      <CancelEventModalContent event={event} handleClose={(e) => { e.preventDefault(); handleCancelModalChange(false); }} />
                    </Modal>
                  </div>}
              </div>
            </PopoverContent>
          </Popover>
        </div>

        {/* <div
          className={`absolute h-full w-full right-0 top-0 bg-no-repeat bg-cover bg-center opacity-40 z-0 pointer-events-none scale-100 transition-all group-hover:scale-110 group-hover:opacity-70`}
          style={{
            backgroundImage: `url(${event.bannerImage})`,
            maskImage: 'linear-gradient(to left, rgba(0, 0, 0, 1.0) 20%, transparent 100%)',
          }}
        /> */}
      </li>
    </LinkWrapper>
  )
}

export default EventCard;
