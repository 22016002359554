import { create } from 'zustand';
import { setDefaultActivityState } from "@repo/utils/events";
import { queryClient } from '@/lib/queryClient';

const findPhaseInfoFromTournamentRoundId = (allPhases, tournamentRoundId) => {
	let selectedPhase = {};
	allPhases.forEach((phase) => {
		(phase?.tournament?.rounds || []).forEach((tournamentRound) => {
			if (tournamentRound.id === tournamentRoundId) selectedPhase = phase;
		})
	})

	return {
		phasePod: selectedPhase || [],
		allPhases: allPhases?.filter((phase) => phase?.stage === selectedPhase.stage) || {},
		tournament: selectedPhase.tournament || {},
		tournamentRound: selectedPhase?.tournament?.rounds?.find((tournamentRound) => tournamentRound.id === tournamentRoundId) || {},
	}
};

const findPhaseInfoFromPhaseId = (allPhases, activityPhaseId) => {
	let selectedPhase = {};
	allPhases.forEach((phase) => {
		if (phase.id === activityPhaseId) selectedPhase = phase;
	})

	return {
		phasePod: selectedPhase || [],
		allPhases: allPhases?.filter((phase) => phase?.stage === selectedPhase.stage) || {},
		tournament: null,
		tournamentRound: null,
	}
};

export const useEventStore = create((set) => ({
	setDefault: (activity, event, currentPhaseId) => set(() => {
		return {
			activity,
			event,
			refetch: () => {
				queryClient.invalidateQueries();
			},
			...setDefaultActivityState(activity, currentPhaseId),
		};
	}),
	onChangeStageSelection: ({ activityPhaseId, tournamentRoundId }) => set((initialData) => {
		let activePhases = initialData?.activePhases;
		let activePhasePod = initialData?.activePhasePod;
		let currentPhaseId = initialData?.currentPhaseId;
		let currentTournament = initialData?.currentTournament;
		let currentTournamentRound = initialData?.currentTournamentRound;
		if (tournamentRoundId) {
			const { allPhases, phasePod, tournament, tournamentRound } = findPhaseInfoFromTournamentRoundId(initialData?.allPhases || [], tournamentRoundId);
			activePhases = allPhases;
			activePhasePod = phasePod;
			currentPhaseId = phasePod.id;
			currentTournament = tournament;
			currentTournamentRound = tournamentRound;
		} else if (activityPhaseId) {
			const { allPhases, phasePod, tournament, tournamentRound } = findPhaseInfoFromPhaseId(initialData?.allPhases || [], activityPhaseId)
			activePhases = allPhases;
			activePhasePod = phasePod;
			currentPhaseId = phasePod.id;
			currentTournament = tournament;
			currentTournamentRound = tournamentRound;
		}

		return {
			...initialData,
			activePhases,
			activePhasePod,
			currentPhaseId,
			currentTournament,
			currentTournamentRound,
		}
	}),
	activePhases: [],
	activePhasePod: {},
	activity: {},
	event: {},
	allPhases: [],
	currentPhaseId: null,
	currentTournament: {},
	currentTournamentRound: null,
	setShouldSetDefaultEventState: (boolValue) => set((initialData) => {
		return {
			...initialData,
			shouldSetDefaultEventState: boolValue,
		};
	}),
	shouldSetDefaultEventState: true,
	refetch: () => { },
}));
