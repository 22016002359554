import { useState } from "react";
import dayjs from "dayjs";
import { Link } from "@tanstack/react-router";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "sonner";

import {
  RiCalendarLine,
  RiExternalLinkLine,
  RiFileCopyLine,
  RiGroupLine,
  RiPlayFill,
  RiShareLine,
  RiVipDiamondFill,
} from "@remixicon/react";
import { Button } from "@repo/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@repo/ui/popover";
import { getActivityPlayUrl, getGameNetworkUrl } from "@repo/utils/url";
import { Tooltip, TooltipContent, TooltipTrigger } from "@repo/ui/tooltip";
import { sortTicketPrices } from "@/components/Flows/CreateEvent/utils/ticketPrices";
import ActionButtons from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Header/ActionButtons";
import AdditionalActions from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Header/AdditionalActions";
import { getTicketPriceDisplay } from "@repo/utils/currency";
import { Modal, ModalTrigger } from "@repo/ui/modal";
import FinalizeActivityModal from "@/components/Modal/Event/Organize/FinalizeActivityModal";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const statuses = {
  draft: "text-zinc-100 bg-zinc-800/70 border-zinc-100/60",
  published: "text-blue-300 bg-blue-600/70 border-blue-400/60",
  inProgress: "text-yellow-300 bg-yellow-800/70 border-yellow-500/60",
  complete: "text-green-400 bg-green-800/80 border-green-500/60",
  canceled: "text-red-500 bg-rose-800/20 border-red-600/20",
};

const SingleEventHeader = ({
  activeTab,
  activity,
  establishment,
  event,
  game,
  setActiveTab,
  tabs,
} = {}) => {
  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation({
    mutationFn: async () => {
      await queryClient.invalidateQueries([]);
    }
  })
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const ticketPrice = activity?.ticketPrices?.sort(sortTicketPrices)?.[0];
  const [isFinalizeActivityModalOpen, setIsFinalizeActivityModalOpen] =
    useState(false);
  let environment = "production";
  const origin = location.origin;
  if (
    origin.includes("localhost:") ||
    origin.includes(".dev.") ||
    origin.includes("dev.")
  ) {
    environment = "development";
  }

  const hasGenericPhase = Object.values(activity?.phases || {})
    .flat()
    .some((phase) => phase.activityPhaseType === "generic");
  const shareLinks = [
    {
      name: "Store Website Link",
      url: `https://${establishment.slug}.events.${environment === "production" ? "" : "dev."}carde.io/events/${event.id}`,
      show: establishment?.slug?.length > 0,
    },
    {
      name: "Play Network Link",
      url: `${getGameNetworkUrl(game, environment)}/events/${event.id}`,
      show: true,
    },
    {
      name: "3rd Party Event Link",
      url: getActivityPlayUrl(activity, event.id, game, environment),
      show:
        activity?.externalReference &&
        Object.values(activity?.externalReference)?.[0]?.publicUrl,
    },
  ].filter((link) => link.show);

  const classNames = (...classes) => classes.filter(Boolean).join(" ");

  return (
    <div className="relative w-full mb-2">
      <div className="flex flex-col justify-between p-4">
        <div className="flex w-full justify-between z-10">
          <div className="w-full">
            <div className="flex mb-2 gap-2">
              <p
                className={classNames(
                  statuses[event.status],
                  "border-[1px] flex-none rounded-full px-[8px] uppercase text-2xs w-max"
                )}
              >
                {event.status}
              </p>
              {(event?.featured || activity?.featured) && (
                <div className="flex border-[1px] items-center justify-center rounded-full px-[8px] uppercase text-2xs w-max gap-2 text-yellow-400 bg-yellow-700/70 border-yellow-400/20">
                  <RiVipDiamondFill className="h-3 w-3 text-yellow-400" />
                  <span>Featured</span>
                </div>
              )}
            </div>
            <div className="flex flex-row gap-2 items-center my-4">
              <h3 className="text-3xl font-bold">{event.name}</h3>
              {activity?.status === "published" && (
                <AdditionalActions
                  activity={activity}
                  event={event}
                  game={game}
                />
              )}
            </div>
            <div className="flex gap-2 mb-8">
              <div className="flex items-center justify-center py-1 px-2 text-3xs uppercase bg-zinc-300/10 rounded-lg border-[1px] border-zinc-200/20 gap-2">
                <RiCalendarLine className="h-3 w-3" />
                <span>
                  {dayjs(event.startsAt).format("MMM DD, YYYY - h:mm A z")}
                </span>
              </div>
              <div className="flex items-center justify-center py-1 px-2 text-3xs uppercase bg-zinc-300/10 rounded-lg border-[1px] border-zinc-200/20 gap-2">
                <RiGroupLine className="h-3 w-3" />
                <span>
                  {activity?.registrationCount > 0
                    ? `${activity?.registrationCount || 0} / ${activity?.capacity} Capacity`
                    : "No Registrations Yet"}
                </span>
              </div>
              {activity?.name && (
                <div className="flex items-center justify-center py-1 px-2 text-3xs uppercase bg-zinc-300/10 rounded-lg border-[1px] border-zinc-200/20 gap-2">
                  <RiPlayFill className="h-3 w-3" />
                  <span>{activity.name}</span>
                </div>
              )}
            </div>
            <div className="flex gap-4 w-full">
              {tabs.map((tab) => {
                if (tab.url) {
                  return (
                    <Link to={tab.url} target="_blank">
                      <Button
                        key={tab.id}
                        className={`flex items-center justify-center gap-1 text-lg==xl font-normal rounded-none px-4 py-2 ${activeTab === tab.id ? "border-b-secondary border-b-[2px]" : ""}`}
                        variant="text"
                      >
                        <span>{tab.name}</span>
                        <RiExternalLinkLine className="h-3 w-3" />
                      </Button>
                    </Link>
                  );
                }
                return (
                  <Button
                    key={tab.id}
                    className={`flex items-center justify-center gap-1 text-xl font-normal rounded-none px-4 py-2 ${activeTab === tab.id ? "border-b-secondary border-b-[2px]" : ""}`}
                    variant="text"
                    onClick={() => setActiveTab(tab.id)}
                  >
                    {tab.name}
                  </Button>
                );
              })}
              <Button size={'sm'} variant={'outlined'} rounded className={'text-zinc-300 border-zinc-300 border-1 ml-auto mr-8 mt-auto h-fit'} loading={isLoading} onClick={mutate}>Refresh</Button>
            </div>
          </div>
          <div className="flex flex-col items-end justify-between gap-2">
            <div className="flex gap-2 items-center justify-end h-12">
              <img
                src={
                  game?.resourceImages?.find(
                    (resImg) => resImg.imageType === "logo"
                  )?.image?.url
                }
                className="h-10 z-10"
              />
              {!["canceled", "draft"].includes(activity?.status) && (
                <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
                  <PopoverTrigger asChild>
                    <button className="pointer rounded-3xl p-2 hover:bg-zinc-200/10">
                      <RiShareLine className="h-8 w-8" aria-hidden="true" />
                    </button>
                  </PopoverTrigger>
                  <PopoverContent
                    className="bg-zinc-950 border-zinc-200/10 w-fit"
                    align="end"
                  >
                    {shareLinks.map((linkInfo) => linkInfo.show && (
                      <div className="flex items-center justify-between p-2 gap-8">
                        <div>{linkInfo.name}</div>
                        <div className="flex items-center justify-center gap-1">
                          <Tooltip delayDuration={200}>
                            <TooltipTrigger>
                              <Link to={linkInfo.url} target="_blank">
                                <button className="p-2 rounded hover:bg-zinc-300/20">
                                  <RiExternalLinkLine className="h-4 w-4" />
                                </button>
                              </Link>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p className="text-sm">
                                Go To {linkInfo.name} in new tab
                              </p>
                            </TooltipContent>
                          </Tooltip>

                          <Tooltip delayDuration={200}>
                            <TooltipTrigger>
                              <button className="p-2 rounded hover:bg-zinc-300/20">
                                <CopyToClipboard
                                  onCopy={() =>
                                    toast.success("Link Copied to Clipboard")
                                  }
                                  text={linkInfo.url}
                                >
                                  <RiFileCopyLine className="h-4 w-4" />
                                </CopyToClipboard>
                              </button>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p className="text-sm">Copy to Clipboard</p>
                            </TooltipContent>
                          </Tooltip>
                        </div>
                      </div>
                    ))}
                  </PopoverContent>
                </Popover>
              )}
            </div>

            <div className="flex flex-col items-end">
              {ticketPrice && activity?.status !== "draft" && (
                <div className="flex justify-center gap-2">
                  <h4 className="text-2xl font-bold mb-2">
                    {getTicketPriceDisplay(ticketPrice)}
                  </h4>
                </div>
              )}

              <div className="flex gap-2">
                <ActionButtons
                  activity={activity}
                  environment={environment}
                  event={event}
                  game={game}
                />

                {activity?.status === "inProgress" && hasGenericPhase && (
                  <div className="flex-shrink-0">
                    <Modal
                      open={isFinalizeActivityModalOpen}
                      onOpenChange={setIsFinalizeActivityModalOpen}
                    >
                      <ModalTrigger asChild>
                        <Button
                          className="flex items-center"
                          onClick={() => setIsFinalizeActivityModalOpen(true)}
                          size="sm"
                          variant="gradient-contained"
                        >
                          <span>Finalize Activity</span>
                        </Button>
                      </ModalTrigger>
                      <FinalizeActivityModal
                        handleClose={() =>
                          setIsFinalizeActivityModalOpen(false)
                        }
                      />
                    </Modal>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="absolute top-0 left-0 w-full h-[800px] bg-cover bg-center opacity-30 z-0 pointer-events-none rounded-t-xl"
        style={{
          backgroundImage: `url(${event.bannerImage})`,
          maskImage:
            "linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 20%, transparent 100%)",
        }}
      />
    </div>
  );
};

export default SingleEventHeader;
