type swapPlayersMutationFnParams = {
  clientAxiosInstance: any;
  tournamentRoundId: string;
  newUserId: string;
  originalTournamentPairingUserId: string;
};

export const swapPlayersMutationFn = async ({
  clientAxiosInstance,
  tournamentRoundId,
  newUserId,
  originalTournamentPairingUserId,
}: swapPlayersMutationFnParams) => {
  return clientAxiosInstance.post(
    `/api/organize/tournamentRounds/${tournamentRoundId}/swap`,
    {
      newUserId,
      originalTournamentPairingUserId,
    },
  );
};
