import React, { useMemo } from "react";

import ActivityConfigContainer from "@/components/Flows/CreateEvent/Steps/ActivityConfiguration/ConfigContainer";
import BackContinueSection from "../../../BackContinueSection";
import { get } from "lodash";
import { useActivityConfiguration } from "@/lib/hooks/useActivityConfiguration";
import { sortByOrder } from "@/lib/createEventHelpers";

const getPowerOfTwoOptions = () => {
  const options = [];
  for (let i = 1; i <= 6; i++) {
    const value = Math.pow(2, i);
    options.push({
      label: value.toString(),
      value: value
    });
  }
  return options;
};

const powerOfTwoOptions = getPowerOfTwoOptions();

const dedupObjects = (array, key) => {
  const seen = new Set();
  return array.filter(item => {
    const value = JSON.stringify(item[key]);
    if (seen.has(value)) {
      return false;
    }
    seen.add(value);
    return true;
  });
};


const StepFour = ({ activeStep, flow, form, onContinue, continueLoading, selectedGame, setStep }) => {
  const stepNumber = 4;
  const isActive = activeStep === stepNumber;

  const { watch, setValue } = form;
  const watchAllFields = watch();

  const fields = useMemo(() => {
    if (!flow?.fields) return [];
    const flowFields = { ...(flow?.fields || {}) };

    const advancesNextPhaseRaw = flowFields?.['Advanced Options']
      ?.['configuration.tournaments.0.advancesNextPhase']?.[0]
    const advancesNextPhaseConfig = advancesNextPhaseRaw
      ?.['configuration.tournaments.0.advancesNextPhase'];

    if (advancesNextPhaseConfig) {
      const newAdvanceNextOptions = {
        ...advancesNextPhaseConfig,
        options: dedupObjects([
          ...(advancesNextPhaseConfig.options || []),
          {
            label: 'None', value:
              { type: 'topTotal', value: null }
          },
          ...powerOfTwoOptions.map(option => ({
            label: option.label,
            value: { type: 'dynamicTopTotal', value: option.value }
          })),
        ], 'value'),
        visualization: "selectSingle"
      };

      const newRoundsOptions = {
        options: Array.from({ length: 21 }, (_, i) => ({
          label: !i ? 'Dynamic' : `${i} Round${i === 1 ? '' : 's'}`,
          value: i
        })),
        visualization: "selectSingle",
        default: 0,
        label: "Swiss Rounds"
      }
      // Only update if we have valid path
      if (flowFields?.['Advanced Options']
        ?.['configuration.tournaments.0.advancesNextPhase']?.[0]) {
        flowFields['Advanced Options']['configuration.tournaments.0.advancesNextPhase'][0]['configuration.tournaments.0.advancesNextPhase'] = newAdvanceNextOptions;
        flowFields['Advanced Options']['configuration.tournaments.0.rounds'] = [{
          conditions: {
            all: dedupObjects([...(advancesNextPhaseRaw.conditions.all || []), {
              "field": "configuration.tournaments.0.tournamentFormat",
              "value": "swiss",
              "operator": "equal"
            }], 'field')
          },
          'configuration.tournaments.0.rounds': newRoundsOptions
        }];
      }
    }

    return sortByOrder(Object.keys(flowFields), flow?.order || []).map((key) => ({
      field: key,
      data: flowFields?.[key],
    }));
  }, [flow]);

  const checkFieldsRef = useActivityConfiguration(fields, watchAllFields, setValue);

  return (
    <>
      <ActivityConfigContainer fields={fields} flow={flow} form={form} isActive={isActive} />
      <BackContinueSection
        className={`${activeStep === stepNumber ? 'block' : 'hidden'}`}
        isBackDisabled={selectedGame.official}
        isContinueDisabled={!Array.from(checkFieldsRef.current).every((field) => get(watchAllFields, field) !== undefined && get(watchAllFields, field) !== null)}
        onBack={() => {
          if (!selectedGame?.official) {
            setStep(stepNumber - 1)
          }
        }}
        continueText={'Save and Publish'}
        onContinue={onContinue}
        continueLoading={continueLoading}
      />
    </>
  )
}

export default StepFour;
