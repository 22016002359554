import { useCallback, useMemo, useState } from "react";

import OneVsOnePlayerInfo from "@/components/Cards/Variants/Pairings/OneVsOne/PlayerInfo";
import { Modal, ModalTrigger } from "@repo/ui/modal";
import ManagePairingModal from "@/components/Modal/Event/Pairings/ManagePairing";

const colors = {
  none: 'rgba(25, 25, 25, 0.85)',
  win: 'rgba(0, 128, 0, 0.7)',
  loss: 'rgba(178, 34, 34, 0.7)',
  tie: 'rgba(45, 85, 255, 0.7)'
}
const statuses = {
  none: {
    boxShadow: `inset 0 0 0 2000px ${colors.none}`,
  },
  win: {
    boxShadow: `inset 0 0 0 2000px ${colors.win}`,
  },
  loss: {
    boxShadow: `inset 0 0 0 2000px ${colors.loss}`,
  },
  tie: {
    boxShadow: `inset 0 0 0 2000px ${colors.tie}`,
  }
}

const OneVsOnePairingCard = ({ pairing, isPreview = false }) => {
  const [isManagePairingModalOpen, setIsManagePairingModalOpen] = useState(false);

  const seatOne = pairing?.tournamentPairingUsers?.[0] || {};
  const seatTwo = pairing?.tournamentPairingUsers?.[1] || {};

  const { seatOneWins, seatTwoWins } = useMemo(() => {
    let oneWins = 0;
    let twoWins = 0;
    if (!(pairing?.result?.isDoubleLoss)) {
      pairing?.result?.games?.forEach((game) => {
        if (game?.winner === seatOne?.id) {
          oneWins += 1;
        }
        if (game?.winner === seatTwo?.id) {
          twoWins += 1;
        }
      });
    }
    return { seatOneWins: oneWins, seatTwoWins: twoWins };
  }, [pairing?.result?.isDoubleLoss, pairing?.result?.games, seatOne?.id, seatTwo?.id]);

  const { seatOneOutcome, seatTwoOutcome } = useMemo(() => {
    let oneOutcome = 'none';
    let twoOutcome = 'none';

    if (pairing?.result?.isDoubleLoss) {
      oneOutcome = 'loss';
      twoOutcome = 'loss';
    } else if (seatOneWins > seatTwoWins || !seatTwo?.id) {
      oneOutcome = 'win';
      twoOutcome = 'loss';
    } else if (seatOneWins < seatTwoWins) {
      oneOutcome = 'loss';
      twoOutcome = 'win';
    } else if (seatOneWins !== 0 && seatTwoWins !== 0) {
      oneOutcome = 'tie';
      twoOutcome = 'tie';
    }

    return { seatOneOutcome: oneOutcome, seatTwoOutcome: twoOutcome };
  }, [pairing?.result?.isDoubleLoss, seatOneWins, seatTwo?.id, seatTwoWins]);

  const handleClose = useCallback(() => {
    setIsManagePairingModalOpen(false);
  }, []);

  const getBackgroundStyle = useCallback((outcome, imageUrl) => ({
    backgroundImage: outcome?.length && outcome !== 'none' ? '' : `url(${imageUrl})`,
    ...(statuses?.[outcome] || { boxShadow: `inset 0 0 0 2000px ${colors.none}` }),
  }), []);

  return (
    <>
      <li
        key={pairing.id}
        className="relative mx-2 bg-zinc-800/50 group flex items-center hover:bg-zinc-700/70 border-[1px] border-zinc-200/40 overflow-hidden min-h-[90px]"
        style={{
          borderRadius: '16px',
        }}
      >
        <Modal open={isManagePairingModalOpen && !!seatTwo?.id} onOpenChange={setIsManagePairingModalOpen}>
          <ModalTrigger asChild>
            <button className="w-full h-full flex justify-between items-center z-10" style={{ textShadow: '1px 1px 2px black' }}>
              <OneVsOnePlayerInfo
                player={seatOne}
                outcome={seatOneOutcome}
              />
              <div className="w-[80px] h-[80px] p-2 flex flex-col justify-center items-center bg-zinc-900/40 border-2 rounded-full border-zinc-100/60">
                {isPreview ? (
                  <>
                    <div className="text-2xs tracking-[2px]">EDIT</div>
                    <div className="text-2xs tracking-[2px]">TABLE</div>
                    <div className="text-xl">{pairing.tableNumber}</div>
                  </>
                ) : (
                  <>
                    <div className="text-2xs tracking-[2px]">TABLE</div>
                    <div className="text-3xl">{pairing.tableNumber}</div>
                  </>
                )}
              </div>
              <OneVsOnePlayerInfo
                isReversed
                player={seatTwo}
                outcome={seatTwoOutcome}
              />
            </button>
          </ModalTrigger>
          <ManagePairingModal handleClose={handleClose} pairing={pairing} isPreview={isPreview} />
        </Modal>
        <div
          className="absolute h-full w-[45%] left-0 top-0 bg-no-repeat bg-cover bg-center opacity-30 z-0 pointer-events-none scale-100 transition-all group-hover:scale-110 group-hover:opacity-85 ml-0"
          style={{
            ...getBackgroundStyle(seatOneOutcome, seatOne?.gameUser?.imageUrl),
            maskImage: 'linear-gradient(to right, rgba(0, 0, 0, 1.0) 20%, transparent 100%)',
          }}
        />
        <div
          className="absolute h-full w-[45%] right-0 top-0 bg-no-repeat bg-cover bg-center opacity-30 z-0 pointer-events-none scale-100 transition-all group-hover:scale-110 group-hover:opacity-85"
          style={{
            ...getBackgroundStyle(seatTwoOutcome, seatTwo?.gameUser?.imageUrl),
            maskImage: 'linear-gradient(to left, rgba(0,0,0, 1.0) 20%, transparent 100%)',
          }}
        />
      </li>
    </>
  );
};

export default OneVsOnePairingCard;
