import { RiArrowRightLine } from "@remixicon/react";

import OrganizeHeaderNavigation
  from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Organize/HeaderNavigation";
import OrganizePairings from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Organize/Pairings";
import { useEventStore } from "@/store/useEventStore";
import { Button } from "@repo/ui/button";

const EventOrganizeTab = () => {
  const { activity, activePhases, activePhasePod } = useEventStore();

  if (activity?.externalReference && Object.values(activity?.externalReference)?.length > 0) {
    const externalRef = Object.values(activity?.externalReference)?.[0];
    if (!externalRef?.canIFrame) {
      return (
        <div className='h-[300px] bg-zinc-950 rounded-lg flex flex-col items-center justify-center gap-8'>
          <h3 className='text-xl'>{externalRef?.instructions || `Please run this event on the external platform${externalRef?.hostingUrl ? 'using the link below' : '.'}`}</h3>
          {externalRef?.hostingUrl && (
            <a href={externalRef?.hostingUrl} target="_blank" rel="noopener noreferrer">
              <Button variant='gradient-contained'>
                <div className='flex items-center gap-2'>
                  <span className='text-lg'>Run Event</span> <RiArrowRightLine className='h-6 w-6' />
                </div>
              </Button>
            </a>
          )}
        </div>
      )
    }
    return (
      <div className='z-10 h-full'>
        <iframe
          className="w-full h-[700px] z-10 relative"
          id="inlineFrameExample"
          title="Organize Event"
          src={Object.values(activity?.externalReference)?.[0]?.hostingUrl}
        />
      </div>
    )
  } else {
    return (
      <div className='z-10 h-full'>
        <OrganizeHeaderNavigation />
        <OrganizePairings activePhasePod={activePhasePod} activePhases={activePhases} />
      </div>
    )
  }

}

export default EventOrganizeTab;
