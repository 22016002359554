import React, { useState } from 'react';
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "sonner";

import { webAxiosInstance } from "@/utils/axios";
import { useEventStore } from "@/store/useEventStore";
import { Button } from "@repo/ui/button";
import { ModalFooter } from "@repo/ui/modal";
import PlayerSection from "@/components/Modal/Event/Pairings/ManagePairing/OneVsOne/PlayerSection";
import { queryClient } from "@/lib/queryClient";
import { swapPlayersMutationFn } from "@repo/api/base";

const SwapPlayer = ({ handleClose, playerOne, playerTwo, swappingPlayerId, activity }) => {
  const playerToSwap = playerOne?.id === swappingPlayerId ? playerOne : playerTwo;
  const [newPlayerId, setNewPlayerId] = useState(null);
  const { activePhasePod, currentTournamentRound } = useEventStore();

  const { data: fullRoster, isLoading } = useQuery({
    queryKey: [activePhasePod?.id, activity?.id, 'roster', 'swap'],
    queryFn: async () => {
      if (!activity?.gameId || !activePhasePod?.id) {
        return [];
      }

      const url = `/api/play/activityPhases/${activePhasePod.id}/roster`;
      
      const res = await webAxiosInstance.get(url, {
        headers: {
          'Game-Id': activity.gameId,
        },
        params: {
          limit: 9999
        },
      });

      if (!res.data?.data) {
        return [];
      }

      const rawResponse = res.data.data;

      // Filter the roster to only show eligible players
      const filteredRoster = rawResponse
        .filter(player => {
          const isInCurrentMatch = [
            playerOne?.user?.id,
            playerTwo?.user?.id
          ].includes(player.user?.id);

          const isActive = player.status !== 'dropped';
          return isActive && !isInCurrentMatch;
        })
        .map((player, index) => {
          const displayName = player.user?.displayName || player.displayName;
          
          return {
            id: player.id,
            userId: player.userId || player.user?.id,
            label: displayName,
            key: player.id || index,
            rawPlayer: player
          };
        });

      return filteredRoster;
    },
    enabled: Boolean(activity?.gameId && activePhasePod?.id)
  });

  const tournamentRoundId = currentTournamentRound?.id;

  const submitNewPairingMutation = useMutation({
    mutationFn: async ({ clientAxiosInstance, tournamentRoundId, newUserId, originalTournamentPairingUserId }) => {
      if (!tournamentRoundId) {
        throw new Error('Missing tournament round ID');
      }

      return swapPlayersMutationFn({
        clientAxiosInstance,
        tournamentRoundId,
        newUserId,
        originalTournamentPairingUserId
      });
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message || 'Failed to swap players');
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['tournamentRound', tournamentRoundId]);
      handleClose();
      toast.success('Pairing successfully edited');
    }
  });

  const [swappingPlayer, setSwappingPlayer] = useState(
    playerOne?.id === swappingPlayerId ? playerOne : playerTwo
  );

  const handleSwapClick = () => {
    if (!newPlayerId || !tournamentRoundId) {
      toast.error('Missing required data for swap');
      return;
    }

    const selectedPlayer = fullRoster?.find(p => p.id === newPlayerId);
    const newUserId = selectedPlayer?.userId || selectedPlayer?.user?.id;
    
    if (!newUserId) {
      toast.error('Could not find user ID for selected player');
      return;
    }

    submitNewPairingMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      tournamentRoundId,
      newUserId,
      originalTournamentPairingUserId: playerToSwap.id
    });
  };

  return (
    <>
      <div className='flex items-start justify-center w-full'>
        <PlayerSection
          isSwapping={swappingPlayer?.id === playerOne?.id}
          player={playerOne}
          roster={fullRoster || []}
          setUpdatedPlayer={setNewPlayerId}
          updatedPlayer={newPlayerId}
          isLoading={isLoading}
          setPlayerToSwap={player => {
            setSwappingPlayer(player);
            setNewPlayerId(null);
          }}
        />

        <PlayerSection
          isSwapping={swappingPlayer?.id === playerTwo?.id}
          player={playerTwo}
          roster={fullRoster || []}
          setUpdatedPlayer={setNewPlayerId}
          updatedPlayer={newPlayerId}
          isLoading={isLoading}
          setPlayerToSwap={player => {
            setSwappingPlayer(player);
            setNewPlayerId(null);
          }}
        />
      </div>

      <ModalFooter className='items-end'>
        <div>
          <Button
            onClick={handleClose}
            style={{ padding: '0.75rem 2rem', fontSize: '0.75rem' }}
            variant='text'
          >
            Cancel
          </Button>

          <Button
            className='h-fit'
            disabled={!newPlayerId || submitNewPairingMutation.isLoading || !tournamentRoundId}
            onClick={handleSwapClick}
            variant='gradient-contained'
            style={{ padding: '0.75rem 2rem', fontSize: '0.875rem' }}
          >
            {submitNewPairingMutation.isLoading ? 'Swapping...' : 'Swap Player'}
          </Button>
        </div>
      </ModalFooter>
    </>
  )
}

export default SwapPlayer;
