import { useEffect, useState } from "react";
import { debounce } from "lodash";
import { useQuery } from "@tanstack/react-query";

import { useEventStore } from "@/store/useEventStore";
import { webAxiosInstance } from "@/utils/axios";
import { getPairingsMutationFn } from "@repo/api/base";
import PairingsTable from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Organize/Pairings/PairingsTable";
import { usePrevious } from "@/components/Card/CardHeader";
import { pusher } from "@/lib/pusher";
import Loading from "@/components/Loading";
import NonTournamentPairing
  from "@/_pages_/auth/Entity/Store/Tools/Events/Single/Tabs/Organize/Pairings/NonTournamentPairing";
import PairingsTableHeader from "./PairingsTableHeader";

const OrganizePairings = ({ activePhasePod, activePhases }) => {
  const { activity, refetch } = useEventStore();
  const [roundId, setRoundId] = useState(null);
  const currentTournamentRound = activePhasePod?.tournament;
  const [filters, setFilters] = useState({ page: 1, });
  const [searchInput, setSearchInput] = useState('');
  const [isFetchingPairings, setIsFetchingPairings] = useState(false);
  const previousTournamentRound = usePrevious(currentTournamentRound);
  const previousSearchInput = usePrevious(searchInput);

  useEffect(() => {
    setRoundId(activePhasePod?.tournament?.rounds[activePhasePod?.tournament?.currentRound - 1]?.id || null);

    return () => {
      setRoundId(null);
    };
  }, [activePhasePod?.tournament?.currentRound, activePhasePod?.tournament?.rounds]);

  const fetchPairings = async () => {
    const searchParams = new URLSearchParams({});

    if (searchInput) {
      searchParams.append('name', searchInput)
    }
    if (filters?.resultSubmitted === true || filters?.resultSubmitted === false) {
      searchParams.append('resultSubmitted', filters.resultSubmitted);
    }
    if (filters?.page) {
      searchParams.append('page', filters.page);
    }

    if (roundId) {
      try {
        setIsFetchingPairings(true);
        const res = await getPairingsMutationFn({
          clientAxiosInstance: webAxiosInstance,
          tournamentRoundId: roundId,
          data: {
            headers: {
              'Game-Id': activity.gameId,
            },
          },
          searchParams,
        })

        setIsFetchingPairings(false);

        return res?.data;
      } catch (e) {
        throw new Error(e);
      }
    }
  }

  const { data: pairings, isFetching, refetch: refetchPairings } = useQuery({
    queryKey: [activity.id, 'activity', 'pairings', filters, roundId],
    queryFn: fetchPairings,
    enabled: Boolean(roundId),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  // useEffect(() => {
  //   const pusherChannel = pusher.subscribe('organizers');
  //   pusherChannel.bind('activity-pairing-refresh-all', () => {
  //     if (!isFetchingPairings) {
  //       refetch();
  //       refetchPairings();
  //     }
  //   });
  // }, [isFetchingPairings, refetch, refetchPairings]);

  useEffect(() => {
    if (previousTournamentRound?.id && previousTournamentRound?.id !== currentTournamentRound?.id) {
      refetch();
      refetchPairings();
    }
  }, [currentTournamentRound?.id, previousTournamentRound?.id, refetch, refetchPairings]);

  useEffect(() => {
    const debouncedSearch = debounce(refetchPairings, 500);
    if (searchInput || (!searchInput && previousSearchInput)) {
      debouncedSearch();
    }
    return () => {
      debouncedSearch.cancel();
    };
  }, [filters, previousSearchInput, refetchPairings, searchInput]);

  if (activePhasePod?.status === 'generating') {
    return (
      <div className='flex flex-col items-center justify-center gap-8 h-96 bg-zinc-950 mt-4 rounded-lg'>
        <h3 className='text-2xl'>Generating Pairings...</h3>
        <Loading />
      </div>
    );
  }

  if (!currentTournamentRound?.id && activePhasePod?.activityPhaseType !== 'draft') {
    return (
      <NonTournamentPairing />
    )
  }

  if (activePhasePod?.activityPhaseType === 'draft' && !currentTournamentRound?.id) {
    return (
      <div className='w-full flex flex-col items-center justify-center gap-8 bg-zinc-950 mt-4 rounded-lg'>
        <NonTournamentPairing />
      </div>
    )
  }


  return (
    <>
      <div className='w-full p-4 bg-zinc-950/90 rounded-lg flex items-center flex-col gap-4 mt-4'>
        <PairingsTableHeader filters={filters} refetchPairings={refetchPairings} searchInput={searchInput} setFilters={setFilters} setSearchInput={setSearchInput} activePhasePod={activePhasePod} activePhases={activePhases} />
      </div>
      <div className='w-full p-4 bg-zinc-950/90 rounded-lg flex items-center flex-col gap-4 mt-4'>
        <PairingsTable
          activePhasePod={activePhasePod}
          filters={filters}
          isFetching={isFetching}
          pairings={pairings?.data}
          pagination={pairings?.pagination}
          refetchPairings={refetchPairings}
          searchInput={searchInput}
          setFilters={setFilters}
          setSearchInput={setSearchInput}
        />
      </div>
    </>
  )
}

export default OrganizePairings;
