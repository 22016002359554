import Intercom from "@intercom/messenger-js-sdk";

export default function IntercomProvider({ auth }) {
  if (!auth?.user?.email) return null;
  Intercom({
    app_id: "h9vgtgqh",
    user_id: auth.user?.id,
    email: auth.user?.email,
    hide_default_launcher: true,
  });

  return null;
}
