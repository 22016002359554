import { Fragment, useState, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { RiFilter3Line } from "@remixicon/react";
import { useEventsSearchStore } from "@/store/useEventsSearchStore";
import { useApprovedGames } from "@/store/useGameStore";

const FilterEventsDropdown = () => {
  const { data: approvedGames } = useApprovedGames();
  const { games, setGames } = useEventsSearchStore();
  const [selectedGames, setSelectedGames] = useState(games);

  useEffect(() => {
    setSelectedGames(games);
  }, [games]);

  const onGameSelect = (gameId, isSelected) => {
    if (isSelected) {
      setSelectedGames(prev => prev.filter(selectedGameId => selectedGameId !== gameId));
    } else {
      setSelectedGames(prev => [...prev, gameId]);
    }
  };

  const handleApplyFilters = (close) => {
    setGames(selectedGames);
    close();
  };

  const handleClose = () => {
    setGames(selectedGames);
  };

  return (
    <Popover className="relative">
      <Popover.Button className="flex items-center gap-2 px-4 py-2 rounded-lg font-medium bg-zinc-800 text-zinc-300 hover:bg-zinc-700">
        <RiFilter3Line className='h-5 w-5' />
        Filters
        {games.length > 0 && (
          <span className="flex items-center justify-center size-5 text-xs font-medium bg-red-500 text-white rounded-full">
            {games.length}
          </span>
        )}
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
        afterLeave={handleClose}
      >
        <Popover.Panel className="absolute left-0 z-50 mt-5">
          {({ close }) => (
            <div className="w-80 shrink rounded-xl bg-black p-4 text-sm font-semibold leading-6 shadow-lg ring-1 ring-gray-900/5">
              <div className='flex flex-col rounded-md bg-zinc-950'>
                <div className="flex items-center justify-between px-4 py-2 border-b border-zinc-800">
                  <h3 className="text-lg font-semibold text-zinc-100">Filter by Game</h3>
                  <button
                    onClick={() => handleApplyFilters(close)}
                    className="px-3 py-1 text-sm font-medium text-white bg-orange-700 rounded-lg hover:bg-orange-600"
                  >
                    Apply Filters
                  </button>
                </div>
                
                <div className="p-4 max-h-[60vh] overflow-auto">
                  {approvedGames?.map((game) => (
                    <button
                      key={game.id}
                      onClick={() => onGameSelect(game.id, selectedGames.includes(game.id))}
                      className={`flex items-center gap-2 w-full px-4 py-2 my-1 hover:bg-zinc-900 text-left rounded`}
                    >
                      <div className="flex items-center gap-2 flex-1">
                        <div className={`size-4 rounded border ${
                          selectedGames.includes(game.id) 
                            ? 'bg-secondary border-secondary' 
                            : 'border-zinc-600'
                        } flex items-center justify-center`}>
                          {selectedGames.includes(game.id) && (
                            <svg className="size-3 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3">
                              <polyline points="20 6 9 17 4 12" />
                            </svg>
                          )}
                        </div>
                        <span className="text-zinc-100">{game.name}</span>
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}

export default FilterEventsDropdown;
