import { Button } from "@repo/ui/button";

const BackContinueSection = ({
  className,
  backText = 'Back',
  continueText = 'Continue',
  isBackDisabled = false,
  isContinueDisabled = false,
  continueLoading = false,
  onBack,
  onContinue,
}) => {
  return (
    <div className={`bg-zinc-950 p-4 fixed bottom-0 left-0 flex justify-between gap-2 w-full z-40 ${className}`}>
      <Button
        className='text-xs'
        disabled={isBackDisabled}
        onClick={onBack}
        variant='text'
      >
        {backText}
      </Button>
      <Button
        disabled={isContinueDisabled}
        loading={continueLoading}
        className='text-sm px-8'
        onClick={onContinue}
        variant='gradient-contained'
      >
        {continueText}
      </Button>
    </div>
  )
}

export default BackContinueSection;
