import { createFileRoute } from '@tanstack/react-router'
import GameLandingPage from '@/_pages_/auth/Entity/Game/Landing';
import { useRootStore } from '@/store/useRootStore';
import { findEntityById } from "@/utils/entity/findEntityById";
import { queryClient } from '@/lib/queryClient';
import { queryOptions } from "@tanstack/react-query";

import StoreLandingPage from '@/_pages_/auth/Entity/Store/Overview';
import Loading from '@/components/Loading';
import { findOrganizationByEntityId } from '@/utils/entity/allOrgEntities';
import { getEstablishmentResourceImages } from "@/data/establishment/images/getEstablishmentResourceImages";
import { toast } from 'sonner';

// eslint-disable-next-line react-refresh/only-export-components
export const resourceImageOptions = ({ establishmentId }) => queryOptions({
	queryKey: [establishmentId, 'resourceImages'],
	queryFn: async () => await getEstablishmentResourceImages({ establishmentId }),
	enabled: !!establishmentId

})

export const Route = createFileRoute('/_authenticated/entity/$entityId/')({
	loader: async ({ params }) => {
		const { entityId } = params;
		const userData = await queryClient.getQueryData(['userData']);
		const org = findOrganizationByEntityId(userData?.organizations, entityId);
		const entity = findEntityById(org.entities, entityId);
		const establishment = userData?.establishments?.find((establishment) => establishment.entityId === entityId);

		if (entity.type === 'Establishment') {
			if(establishment?.id) {
				try {
					await queryClient.ensureQueryData(resourceImageOptions({ establishmentId: establishment?.id }))
				} catch (error) {
					toast.error('Error fetching establishment resource images')
				}
			} else {
				toast.error('Establishment not found')
			}
		}

	},
	component: () => <EntityLanding />,
	pendingComponent: () => <Loading fullScreen />,
});

const EntityLanding = () => {
	const { entityId } = Route.useParams();
	const { range } = Route.useSearch();
	const userData = useRootStore(state => state.userData);

	const org = findOrganizationByEntityId(userData?.organizations, entityId);
	const entity = findEntityById(org?.entities, entityId);

	if (entity.type === 'Establishment') {
		return (
			<StoreLandingPage entityId={entityId} userData={userData} />
		)
	}

	if (entity.type === 'Game') {
		return (
			<GameLandingPage timeRange={range} entityId={entityId} />
		)
	}

	return (
		<div>Hello</div>
	);
};
