import React from 'react';
import ButtonGroupMultiField from './Fields/ButtonGroupMulti';
import ButtonGroupSingleField from './Fields/ButtonGroupSingle';
import ConstantField from './Fields/Constant';
import HiddenField from './Fields/Hidden';
import RadioField from './Fields/Radio';
import SelectMultiField from './Fields/SelectMulti';
import SelectSingleField from './Fields/SelectSingle';
import SliderField from './Fields/Slider';
import SwitchField from './Fields/Switch';
import AutocompleteField from './Fields/Autocomplete';

const ResourceOptionVisualizationType = {
  hidden: 'hidden',
  constant: 'constant',
  buttonGroupSingle: 'buttonGroupSingle',
  buttonGroupMulti: 'buttonGroupMulti',
  selectSingle: 'selectSingle',
  selectMulti: 'selectMulti',
  radio: 'radio',
  slider: 'slider',
  switch: 'switch',
  autocomplete: 'autocomplete',
};

export const VisualizationField = ({ visualization, ...props }) => {
  const visualizationComponents = {
    [ResourceOptionVisualizationType.hidden]: HiddenField,
    [ResourceOptionVisualizationType.constant]: ConstantField,
    [ResourceOptionVisualizationType.buttonGroupSingle]: ButtonGroupSingleField,
    [ResourceOptionVisualizationType.buttonGroupMulti]: ButtonGroupMultiField,
    [ResourceOptionVisualizationType.selectSingle]: SelectSingleField,
    [ResourceOptionVisualizationType.selectMulti]: SelectMultiField,
    [ResourceOptionVisualizationType.radio]: RadioField,
    [ResourceOptionVisualizationType.slider]: SliderField,
    [ResourceOptionVisualizationType.switch]: SwitchField,
    [ResourceOptionVisualizationType.autocomplete]: AutocompleteField,
  };

  const Component = visualizationComponents[visualization] || null;
  return Component ? <Component {...props} /> : null;
}

export default VisualizationField;
