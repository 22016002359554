import { orderBy } from 'lodash';

function getCurrentPhases(data) {
  const results = [];

  for (const key in data) {
    for (const item in data[key]) {
      const phase = data[key][item];
      if (['inProgress', 'generating', 'preview'].includes(phase.status)) {
        results.push(phase);
      }
    }
  }

  return results;
}

const flattenPhases = (phases) => {
  const results = [];

  for (const key in phases) {
    for (const item in phases[key]) {
      const phase = phases[key][item];
      results.push(phase);
    }
  }

  return results;
};

export const setDefaultActivityState = (activity, currentPhaseId) => {
  let activePhases = getCurrentPhases(activity.phases);
  if (!activePhases.length) {
    activePhases = flattenPhases(activity.phases) ?? [];
  }

  //   const arrDepth = getArrayDepth(activePhases);
  //   if (arrDepth >= 2) {
  //     activePhases = flatten(activePhases);
  //   }

  const checkAllCompleted = activePhases?.every((phase) => phase?.status === 'complete');

  const tournamentPhases = activePhases?.filter((phase) => phase?.tournament !== undefined) || [];

  if (checkAllCompleted) {
    activePhases = orderBy(activePhases, 'stage', 'desc');
  }

  const isCurrentPhaseIdValid = flattenPhases(activity.phases)
    .map((p) => p.id)
    .includes(currentPhaseId);
  const currentPhaseObj = flattenPhases(activity.phases).find((p) => p.id === currentPhaseId);

  return {
    // active phase is an array of phases
    allPhases: flattenPhases(activity.phases),
    currentPhaseId: isCurrentPhaseIdValid ? currentPhaseId : activePhases[0]?.id,
    activePhases: activePhases,
    // active phase pod is an object of a pod
    activePhasePod: isCurrentPhaseIdValid ? currentPhaseObj : activePhases[0] ? activePhases[0] : {},
    currentTournamentRound:
      isCurrentPhaseIdValid && currentPhaseObj?.tournament
        ? currentPhaseObj?.tournament?.rounds[currentPhaseObj?.tournament?.currentRound - 1]
        : (tournamentPhases[0]?.tournament?.rounds[tournamentPhases[0]?.tournament?.currentRound - 1] ?? null),
    currentTournament: isCurrentPhaseIdValid ? currentPhaseObj?.tournament : (tournamentPhases[0]?.tournament ?? null),
  };
};
