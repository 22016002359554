const fetchEventRegistrations = async (
  axiosInstance: any,
  eventId: string,
  searchParams: any,
) => {
  return axiosInstance
    .get(
      `/api/organize/events/${eventId}/registrations?${new URLSearchParams(searchParams)}`,
    )
    .then((games: any) => games.data)
    .catch((err: any) => {
      throw err;
    });
};

type fetchEstablishmentGamesQueryOptionsParams = {
  axiosInstance: any;
  eventId: string;
  searchParams: any;
};

export const fetchEventRegistrationsQueryOptions = ({
  axiosInstance,
  eventId,
  searchParams,
}: fetchEstablishmentGamesQueryOptionsParams) => ({
  queryKey: ["event", eventId, "registrations"],
  queryFn: () => fetchEventRegistrations(axiosInstance, eventId, searchParams),
  refetchOnWindowFocus: true,
  refetchOnMount: false,
  refetchOnReconnect: true,
});
