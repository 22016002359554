import { useCallback, useEffect, useMemo, useState } from "react";
import fileDownload from 'js-file-download';
import { useParams } from "@tanstack/react-router";
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';

import CardContainer from "@repo/ui/card/CardContainer";
import CardHeader from '@/components/Card/CardHeader';
import { eventTooltip } from "@repo/utils/constants";
import Pagination from "@repo/ui/card/Pagination";
import { fetchEventRegistrationsQueryOptions } from "@repo/api/web";
import { webAxiosInstance } from "@/utils/axios";
import EventRegistrationCard from "@/components/Cards/Variants/EventRegistrationCard";
import { Button } from '@repo/ui/button';
import { useEventStore } from "@/store/useEventStore";
import { getActivityPhaseRosterMutationFn } from "@repo/api/base";
import { eventQueryOptions } from "@/data/organization/queries";
import { queryClient } from "@/lib/queryClient";
import { Switch } from "@repo/ui/switch";
import { pusher } from "@/lib/pusher";
import { useRegistrationsFilterStore } from "./useRegistrationsFilterStore";
import OrganizeHeaderNavigation from "../Organize/HeaderNavigation";
import PodFilter from "../../PodFilter";


const EventRegistrationsTab = ({ activity, registrations } = {}) => {
  const [roster, setRoster] = useState([]);
  const { activePhasePod, activePhases } = useEventStore();
  const { activeFilterPodId, setActiveFilterPodId } = useRegistrationsFilterStore();
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const { eventId } = useParams({ strict: false });

  const { data: eventRes } = useSuspenseQuery(eventQueryOptions(eventId));

  const [showUnregisteredPlayers, setShowUnregisteredPlayers] = useState(false);

  const { data: phasePodRoster } = useQuery({
    queryKey: [activePhases?.map(p => p?.id)?.join(','), 'activity', 'roster'],
    queryFn: async () => {
      const phasePodRosterRes = await Promise.all(activePhases?.map(async (phase) => {
        const res = await getActivityPhaseRosterMutationFn({ clientAxiosInstance: webAxiosInstance, activityPhaseId: phase?.id, options: { headers: { 'Game-Id': activity?.gameId } } })
        return {
          ...res,
          phase,
        }
      }))
      const flattenedRoster = phasePodRosterRes.map(res => res?.data?.data.map(r => ({ ...r, phase: res?.phase }))).flat();
      return flattenedRoster;
    },
    enabled: Boolean(activePhasePod?.id),
    refetchOnWindowFocus: true,
    refetchOnMount: false,
    refetchOnReconnect: true,
  });


  const refreshCache = useCallback(async () => {
    setIsRefreshing(true);
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ["event", eventId, "registrations"] }),
      queryClient.invalidateQueries({ queryKey: [activePhasePod?.id, 'activity', 'roster'] }),
      queryClient.invalidateQueries({ queryKey: [activePhases?.map(p => p?.id)?.join(','), 'activity', 'roster'] }),
      queryClient.invalidateQueries({ queryKey: [activity?.id, 'activity'] }),
    ]);
    setIsRefreshing(false);
  }, [eventId, activePhasePod?.id, activePhases, activity?.id]);

  useEffect(() => {
    const pusherChannel = pusher.subscribe(`activity-${activity.id}`);
    pusherChannel.bind('roster', () => {
      refreshCache();
    });

    return () => {
      pusherChannel.unbind('roster');
    };
  }, [activity.id, refreshCache]);


  useEffect(() => {
    if (registrations) {
      if (phasePodRoster?.length > 0) {
        const normalizedRoster = registrations.map(registration => {
          const phaseUser = phasePodRoster?.find(r => r?.user?.id === registration?.userId);
          if (phaseUser) {
            return {
              ...registration,
              phaseUser,
            }
          }
          return registration;
        })

        setRoster(normalizedRoster.sort((a, b) => a?.phaseUser?.seed - b?.phaseUser?.seed));
      } else {
        setRoster(registrations);
      }
    }
  }, [registrations, phasePodRoster])

  const csvMutation = useMutation({
    mutationFn: async ({ eventId }) => {
      return await webAxiosInstance.get(`/api/organize/events/${eventId}/registrations/csv`, { responseType: 'blob' })
    }
  })

  const downloadCVS = async () => {
    const value = await csvMutation.mutateAsync({ eventId });
    fileDownload(value.data, `registrations-event-${eventId}.csv`)
  }

  const filteredRoster = useMemo(() => roster?.filter(r => {
    const registration = r?.activityRegistrations?.[0];
    if (showUnregisteredPlayers && !registration) {
      return true
    }
    if (showUnregisteredPlayers && ['unregistered', 'cancelled'].includes(registration?.status)) {
      return true
    }
    return !showUnregisteredPlayers && ['registered', 'checkedIn'].includes(registration?.status)
  }), [roster, showUnregisteredPlayers])

  const activePhaseOutOfBounds = !activePhases?.some(phase => phase?.id === activeFilterPodId);
  const showAll = activePhaseOutOfBounds || activeFilterPodId === 'all';

  return (
    <div className='relative z-10'>
      <div className='flex justify-between items-center z-10 mb-4'>
        <h1 className="text-4xl font-extrabold tracking-tight text-zinc-300">
          Event {activity?.status === 'published' ? 'Registrations' : 'Roster'}
        </h1>
        {/* <Button size={'sm'} variant={'outlined'} rounded className={'text-zinc-300 border-zinc-300 border-1'} loading={isRefreshing} onClick={refreshCache}>Refresh</Button> */}
      </div>

      <div className="mb-4">
        <OrganizeHeaderNavigation showPodSwitcher={false} className="bg-zinc-950/60" />
      </div>

      {registrations?.length === 0 ? (
        <div className='flex items-center justify-center'>
          <h2 className='text-2xl'>No Registrations Yet</h2>
        </div>
      ) : (
        <>
          <div className="flex items-center justify-end gap-2 mb-4">
            <label htmlFor="auto-seed-switch" className="text-sm text-zinc-300">
              Show Unregistered Players
            </label>
            <Switch
              id="auto-seed-switch"
              checked={showUnregisteredPlayers}
              onCheckedChange={() => { setShowUnregisteredPlayers(!showUnregisteredPlayers) }}
            />

          </div>
          <CardContainer className='relative z-10'>
            <CardHeader
              buttonTitle='Add Player'
              filters={{
                hasFilters: activePhases?.length > 0,
                hasSearch: false,
                isFilterModalOpen,
                setIsFilterModalOpen,
                filterComponent: <PodFilter activeFilterPodId={activeFilterPodId} setActiveFilterPodId={setActiveFilterPodId} activePhases={activePhases} activePhaseOutOfBounds={activePhaseOutOfBounds} />,
              }}
              title={activity?.status === 'published' ? 'Registrations' : 'Roster'}
              titleTooltip={eventTooltip}
              rightChildren={<div>
                <Button size={'sm'} variant={'outlined'} className="text-white" rounded onClick={downloadCVS}>
                  Download CSV</Button>
              </div>}
            />
            {roster?.length > 0 && (
              <ul role="list" className="divide-y divide-white/5">
                {filteredRoster.filter(r => !showAll ? r?.phaseUser?.phase?.id === activeFilterPodId : true).map((registration) => (
                  <EventRegistrationCard activity={activity} event={eventRes?.data} registration={registration} />
                ))}
                <Pagination
                  data={{
                    activePage: 1,
                    totalPages: 1,
                    resultsThisPage: filteredRoster?.length,
                    totalResults: filteredRoster?.length,
                  }}
                />
              </ul>
            )}
          </CardContainer>
        </>
      )}
    </div>
  )
}

export default EventRegistrationsTab;
