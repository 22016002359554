import * as React from 'react';
import { useEventStore } from '@/store/useEventStore';
import { delay } from 'lodash';
import { Select, SelectContent, SelectGroup, SelectTextItem, SelectTrigger, SelectValue } from "@repo/ui/select";

const PodSwitcher = ({ activePhases, activePhase }) => {
  const { onChangeStageSelection, setShouldSetDefaultEventState } = useEventStore();

  const setPod = (pod) => {
    if (pod.tournament) {
      setShouldSetDefaultEventState(false);
      delay(() => {
        onChangeStageSelection({ tournamentRoundId: pod.tournament.rounds[pod.tournament.currentRound - 1].id });
      }, 100)
    }
  };

  return (
    <div className="w-[180px]">
      <Select
        value={activePhase?.id}
        onValueChange={(podId) => {
          const selectedPod = activePhases.find(p => p.id === podId);
          if (selectedPod) {
            setPod(selectedPod);
          }
        }}
      >
        <SelectTrigger>
          <SelectValue placeholder="Select a Pod" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {activePhases?.map((pod) => (
              <SelectTextItem key={pod.id} value={pod.id}>
                Pod {pod.pod}
              </SelectTextItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
};

export default PodSwitcher;
