import GameCard from "@/components/Card/Game";
import { useAppliedGames } from "@/store/useGameStore";
import Loading from "@/components/Loading";

const AppliedForGames = () => {
  const { data: appliedGames, isLoading } = useAppliedGames();

  if (isLoading) return <Loading />;
  return (
    <div className="flex flex-row justify-start flex-wrap gap-4">
      {appliedGames?.length > 0 ? (
        appliedGames.map((game, idx) => (
          <GameCard
            key={idx}
            applications={game.applications}
            game={game}
            ourGames
          />
        ))
      ) : (
        <div className="w-full h-[50px] flex items-center justify-center">
          <p className="text-2xs uppercase">No Games Found</p>
        </div>
      )}
    </div>
  );
};

export default AppliedForGames;
