import React from "react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";

import { reportResultsMutationFn } from "@repo/api/base";
import { queryClient } from "@/lib/queryClient";
import { Button } from "@repo/ui/button";
import { webAxiosInstance } from "@/utils/axios";
import {ModalFooter} from "@repo/ui/modal";

const IssueDoubleLossConfirmation = ({ activity, handleClose, pairingId }) => {
  const issueDoubleLossMutation = useMutation({
    mutationFn: reportResultsMutationFn,
    onSuccess: async () => {
      toast.success('Reported Results Successfully!', { toastId: 'reportResultsSuccess' });
      queryClient.invalidateQueries({ queryKey: [activity.id, 'activity', 'pairings']});
      handleClose();
    },
    onError: () => {
      toast.error('Unable to Report Results.', { toastId: 'reportResults' });
    },
  });

  const handleSubmitDoubleLoss = () => {
    issueDoubleLossMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      pairingId: pairingId,
      isDoubleLoss: true,
      games: [],
      gameId: activity.gameId,
    });
  };

  return (
    <>
      <div className='text-xl'>Are you sure you'd like to issue a Double Loss?</div>
      <div className='text-sm mt-2'>Issuing a Double Loss will give both participants a loss for the match and will negatively impact their tiebreakers.</div>

      <ModalFooter className='items-end'>
        <div>
          <Button
            onClick={handleClose}
            style={{ padding: '0.75rem 2rem', fontSize: '0.75rem' }}
            variant='text'
          >
            Cancel
          </Button>

          <Button
            className='h-fit'
            dataTooltipTarget="tooltip-default"
            onClick={handleSubmitDoubleLoss}
            variant='gradient-contained'
            style={{ padding: '0.75rem 2rem', fontSize: '0.875rem' }}
          >
            Issue Double Loss
          </Button>
        </div>
      </ModalFooter>
    </>
  )
}

export default IssueDoubleLossConfirmation;
