import GameCard from "@/components/Card/Game";
import { useAvailableGames } from "@/store/useGameStore";
import Loading from "@/components/Loading";

const AvailableGames = () => {
  const { data: availableGames, isLoading } = useAvailableGames()

  if (isLoading) return <Loading />;
  return (
    <div className='flex justify-between flex-wrap gap-4'>
      {availableGames?.length > 0 ? availableGames.map((gameEstablishment, idx) => (
        <div key={idx} className='flex items-center w-1/5'>
          <GameCard game={gameEstablishment} applications={gameEstablishment.applications} ourGames={!!gameEstablishment.applications} />
        </div>
      )) : (
        <div className='w-full h-[50px] flex items-center justify-center'>
          <p className='text-2xs uppercase'>No Games Found</p>
        </div>
      )}
    </div>
  )
};


export default AvailableGames;
