import { useState } from "react";
import { useEventsSearchStore } from "@/store/useEventsSearchStore";
import { useNavigate } from "@tanstack/react-router";
import CardContainer from "@repo/ui/card/CardContainer";
import CardHeader from "@/components/Card/CardHeader";
import CreateEventModal from "@/components/Modal/Event/CreateEvent";
import EventCalendar from "./EventCalendar";
import EventList from "./EventList";
import { startOfWeek, addDays, format } from "date-fns";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { Tabs, TabsList, TabsTrigger } from "@repo/ui/tabs";
import FilterEventsDropdown from "@/components/Modal/Event/FilterEventsDropdown";
import { RiCalendarLine, RiListUnordered } from "@remixicon/react";
import { VIEW_TYPES, TAB_TYPES } from "./constants";

const formatDateRange = (date) => {
  const startOfWeekDate = startOfWeek(date, { weekStartsOn: 1 });
  const endOfWeekDate = addDays(startOfWeekDate, 6);
  
  const startMonth = format(startOfWeekDate, 'MMMM d');
  const endMonth = format(endOfWeekDate, 'MMMM d');
  const startYear = format(startOfWeekDate, 'yyyy');
  const endYear = format(endOfWeekDate, 'yyyy');

  const isSameMonth = startOfWeekDate.getMonth() === endOfWeekDate.getMonth();
  const isSameYear = startOfWeekDate.getYear() === endOfWeekDate.getYear();

  return (
    <span>
      <span className="font-bold">{startMonth}</span>
      {!isSameYear && <span className="font-medium">, {startYear}</span>}
      {' - '}
      <span className="font-bold">
        {isSameMonth ? format(endOfWeekDate, 'd') : endMonth}
      </span>
      <span className="font-medium">, {endYear}</span>
    </span>
  );
};

const EventToolLanding = ({ 
  activeTab, 
  events, 
  isFetching, 
  pagination, 
  setActiveTab,
  currentDate,
  setCurrentDate,
  viewType,
  setViewType
}) => {
  const [isCreateEventModalOpen, setIsCreateEventModalOpen] = useState(false);
  const { games, setPage, setSearchText, setLimit, limit } = useEventsSearchStore();
  const navigate = useNavigate();

  return (
    <main
      className="grid place-items-center px-6 pt-[108px] pb-12 sm:pb-16 lg:px-8"
      style={{ minHeight: "inherit" }}
    >
      <div className="w-full max-w-7xl">
        <h1 className="text-3xl font-bold text-center mb-8">Event Management</h1>
        <CardContainer>
          <CardHeader
            addModal={() => <CreateEventModal handleClose={() => setIsCreateEventModalOpen(false)} />}
            buttonTitle='Create Event'
            filters={{
              hasActiveFilters: games?.length > 0,
              hasFilters: true,
              hasSearch: true,
              isFilterModalOpen: false,
              onSearch: (searchInput) => {
                setPage(1);
                setSearchText(searchInput);
              },
              filterComponent: <FilterEventsDropdown />,
              setIsFilterModalOpen: undefined,
            }}
            isAddModalOpen={isCreateEventModalOpen}
            setIsAddModalOpen={setIsCreateEventModalOpen}
            title={
              <div className="flex items-center gap-4">
                {viewType === VIEW_TYPES.CALENDAR ? (
                  <div className="flex items-center gap-2">
                    <button
                      type="button"
                      onClick={() => setCurrentDate(prev => addDays(prev, -7))}
                      className="flex items-center justify-center text-gray-400 hover:text-gray-300"
                    >
                      <ChevronLeftIcon className="size-8" aria-hidden="true" />
                    </button>
                    <span className="text-2xl">{formatDateRange(currentDate)}</span>
                    <button
                      type="button"
                      onClick={() => setCurrentDate(prev => addDays(prev, 7))}
                      className="flex items-center justify-center text-gray-400 hover:text-gray-300"
                    >
                      <ChevronRightIcon className="size-8" aria-hidden="true" />
                    </button>
                  </div>
                ) : (
                  <span className="text-2xl font-bold">Event List</span>
                )}
              </div>
            }
            hideTooltip
          />
          
          <div className="grid grid-cols-3 items-center px-6 py-4 border-b border-white/10">
            <div className="flex gap-4 items-center">
              <button
                className={`px-4 py-2 rounded-lg font-medium flex items-center gap-2 ${
                  viewType === VIEW_TYPES.CALENDAR 
                    ? 'bg-orange-700 text-white' 
                    : 'bg-zinc-800 text-zinc-300 hover:bg-zinc-700'
                }`}
                onClick={() => setViewType(VIEW_TYPES.CALENDAR)}
              >
                <RiCalendarLine className="size-5" />
                Week
              </button>
              <button
                className={`px-4 py-2 rounded-lg font-medium flex items-center gap-2 ${
                  viewType === VIEW_TYPES.LIST 
                    ? 'bg-orange-700 text-white' 
                    : 'bg-zinc-800 text-zinc-300 hover:bg-zinc-700'
                }`}
                onClick={() => setViewType(VIEW_TYPES.LIST)}
              >
                <RiListUnordered className="size-5" />
                List
              </button>
            </div>

            {viewType === VIEW_TYPES.LIST && (
              <div className="justify-self-center">
                <Tabs
                  value={activeTab}
                  onValueChange={(value) => {
                    setActiveTab(value)
                    navigate({ search: { tab: value } })
                  }}
                  className="w-[240px]"
                >
                  <TabsList className="grid w-full grid-cols-2">
                    <TabsTrigger 
                      value={TAB_TYPES.UPCOMING}
                      className="data-[state=active]:bg-orange-700"
                    >
                      Upcoming
                    </TabsTrigger>
                    <TabsTrigger 
                      value={TAB_TYPES.COMPLETE}
                      className="data-[state=active]:bg-orange-700"
                    >
                      Complete
                    </TabsTrigger>
                  </TabsList>
                </Tabs>
              </div>
            )}

            <div className="min-w-80">
            </div>
          </div>

          {viewType === VIEW_TYPES.CALENDAR ? (
            <EventCalendar 
              events={events} 
              currentDate={currentDate} 
              isFetching={isFetching}
            />
          ) : (
            <EventList 
              events={events}
              isFetching={isFetching}
              pagination={pagination}
              setPage={setPage}
              setLimit={setLimit}
              limit={limit}
              navigate={navigate}
            />
          )}
        </CardContainer>
      </div>
    </main>
  );
};

export default EventToolLanding;
