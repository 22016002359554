import { useAuth0 } from '@auth0/auth0-react';
import { Outlet, createFileRoute, redirect } from '@tanstack/react-router';
import { useQuery } from "@tanstack/react-query";

import { useRootStore } from '../store/useRootStore';
import { queryClient } from '@/lib/queryClient';
import { currentAdminUserQueryOptions } from '../data/user/fetchCurrentAdminUser';
import Loading from '../components/Loading';
import PageContentContainer from '@/components/PageContentContainer'
import ParallaxBackgroundImage from '@/components/Image/ParallaxBackgroundImage';
import { getAllGamesQueryOptions } from "@/data/games/getAllGames";
import { webAxiosInstance } from "@/utils/axios";
import { useGameStore } from "@/store/useGameStore";

const AuthenticatedPathless = () => {
	const { data: games } = useQuery(getAllGamesQueryOptions(webAxiosInstance))
	useGameStore.setState({ games })
	const auth = useAuth0();
	if (!auth.isAuthenticated) return null;
	return (
		<>
			<ParallaxBackgroundImage
				hasGradient
				hasShine
			/>
			<PageContentContainer>
				<Outlet />
			</PageContentContainer>
		</>
	)
}

export const Route = createFileRoute('/_authenticated')({
	beforeLoad: async ({ context }) => {
		if (context.auth.isAuthenticated) {
			const accessToken = await context.auth.getAccessTokenSilently()
			const userData = await queryClient.ensureQueryData(currentAdminUserQueryOptions(accessToken))
			useRootStore.setState({ accessToken, userData });
		} else {
			if (window.location.pathname === '/') {
				return redirect({
					to: '/login',
					replace: true
				});
			}
			context.auth.loginWithRedirect({ appState: { returnTo: window.location.href } })

		}
	},
	component: AuthenticatedPathless,
	pendingComponent: () => <Loading fullScreen />,
})
