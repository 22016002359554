import { useState } from 'react';
import { capitalize } from 'lodash';
import dayjs from "dayjs";
import { toast } from 'sonner';
import { useMutation } from '@tanstack/react-query';

import advancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";

import { webAxiosInstance } from '@/utils/axios';
import { queryClient } from '@/lib/queryClient';
import { Button } from '@repo/ui/button';
import { Modal, ModalContent, ModalFooter, ModalHeader, ModalTitle } from '@repo/ui/modal';
import { organizerDropUserMutationFn, organizerUndropUserMutationFn } from "@repo/api/base";
import { useEventStore } from "@/store/useEventStore";
import { REGISTRATION_STATUS } from '@/constants';
import { Avatar, AvatarImage } from "@repo/ui/avatar";

dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(utc);

const statuses = {
    active: 'text-green-600 bg-green-500/10 border-green-500/20',
    canceled: 'text-red-700 bg-rose-700/10 border-red-700/20',
    checkedIn: 'text-green-600 bg-green-500/10 border-green-500/20',
    dropped: 'text-red-700 bg-rose-700/10 border-red-700/20',
    registered: 'text-blue-400 bg-blue-400/10 border-blue-400/20',
}

const EventStandingsCard = ({ registration } = {}) => {
    const status = registration?.phaseUser?.status || registration?.status;
    const standing = registration?.phaseUser?.standing;
    const displayName = registration?.user?.displayName;
    const email = registration?.user?.email;
    const seed = registration?.phaseUser?.seed;
    const pod = registration?.phaseUser?.phase?.pod;

    const classNames = (...classes) => classes.filter(Boolean).join(' ');

    return (
        <li key={registration?.id}
            className="relative bg-zinc-800/50 group flex items-center space-x-4 px-4 py-4 sm:px-6 lg:px-8 hover:bg-zinc-700/70 border-y-[2px] border-zinc-400/20 overflow-hidden"
        >
            <div className="min-w-0 flex-auto">
                <div className="flex items-center gap-x-3">
                    <div className='flex items-end gap-1'>
                        <div className='flex flex-col'>
                            <div className='flex items-center gap-2 mb-2'>
                                <div className={classNames(statuses[status], 'flex-none rounded-full p-1')}>
                                    <div className="h-2 w-2 rounded-full bg-current" />
                                </div>
                                <p className={classNames(statuses[status], 'border-[1px] flex-none rounded-full px-[8px] uppercase text-2xs')}>
                                    {status}
                                </p>
                            </div>
                            <h2 className="min-w-0 text-sm font-semibold leading-6 text-white whitespace-nowrap overflow-hidden text-ellipsis">
                                <span className="text-xl font-bold truncate">{displayName}</span>
                            </h2>
                            <p className="min-w-0 text-white">
                                <span className="text-2xs truncate">{email}</span>
                            </p>
                            {seed !== null && seed !== undefined && (
                                <p className="min-w-0 leading-6 text-white font-bold text-sm">
                                    Pod: {pod} - Seat: {seed}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex flex-col border border-zinc-400/20 justify-center items-center z-10 px-4 py-1 rounded-lg min-w-[80px]'>
                <p className='text-2xs uppercase tracking-widest text-white'>Rank</p>
                <p className='font-bold text-white text-xl'>{standing || '-'}</p>
            </div>
        </li>
    );
};

export default EventStandingsCard;
