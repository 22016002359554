import React, { useEffect } from "react";
import { RiErrorWarningLine } from '@remixicon/react';
import countryToCurrency from "country-to-currency";
import { useNavigate, useParams } from "@tanstack/react-router";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/form";
import { Switch } from "@repo/ui/switch";
import { CurrencyInput, Input } from "@repo/ui/input";
import { Button } from "@repo/ui/button";
import { VisualizationField } from "@repo/ui/form";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";


const EventLocationSection = ({ form, hasPayoutEnabled }) => {
  const { entityId } = useParams({ strict: false });
  const navigate = useNavigate();

  const { setValue } = form;
  const formValues = form.watch();
  const googleAddressFields = ['address_components', 'geometry.location', 'formatted_address'];
  const googleBusinessFields = [...googleAddressFields, 'name', 'opening_hours', 'photos', 'website', 'international_phone_number']

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: import.meta.env.VITE_GOOGLE,
    options: {
      types: ['establishment'],
      fields: googleBusinessFields
    }
  });

  useEffect(() => {
    if (formValues.isOnline === true) {
      setValue('address', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.isOnline]);

  useEffect(() => {
    if (formValues.address?.country) {
      setValue('currency', countryToCurrency[formValues.address?.country]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.address]);

  return (
    <div className='p-8 bg-zinc-300/20 rounded flex flex-col gap-4 mb-4 justify-between' id='event-location-section'>
      <div className='flex flex-col gap-4'>
        <h3 className='text-2xl font-bold'>
          Event Details
        </h3>
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Event Name</FormLabel>
              <FormControl>
                <Input placeholder="Event Name" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className='flex justify-between'>
          <FormField
            control={form.control}
            name="capacity"
            render={({ field }) => (
              <FormItem className='w-[48%]'>
                <FormLabel>Max Capacity</FormLabel>
                <FormControl>
                  <Input placeholder="Max Capacity" type='number' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="ticketPrice"
            render={({ field }) => (
              <FormItem className='w-[48%]'>
                <FormLabel>Entry Fee</FormLabel>
                <FormControl>
                  {hasPayoutEnabled ? (
                    <CurrencyInput
                      placeholder="Entry Fee"
                      {...field}
                      onChangeCurrency={(currency) => {
                        setValue('currency', currency);
                      }}
                      selectedCurrency={formValues.currency}
                    />
                  ) : (
                    <div className='flex justify-between items-center gap-1'>
                      <div className='flex items-center gap-2 w-[65%]'>
                        <RiErrorWarningLine className='text-red-600 w-6 h-6' />
                        <p className='text-xs text-zinc-100/60'>
                          You must add a Payout Account to enable ticket prices.
                        </p>
                      </div>
                      <Button onClick={() => { navigate({ to: `/entity/${entityId}/manage/financials` }) }} size='xs' variant='gradient-contained' className='w-fit'>Setup Payout</Button>
                    </div>
                  )}

                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <div>
        <h3 className='text-2xl font-bold'>
          Event Location
        </h3>

        <FormField
          control={form.control}
          name="isOnline"
          render={({ field }) => (
            <FormItem className='flex items-center gap-2 w-[48%]'>
              <FormLabel className='mt-2'>Is Online?</FormLabel>
              <FormControl>
                <Switch
                  id="is-online-switch"
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className='flex'>
        <FormField
          control={form.control}
          name="address.name"
          render={({ field }) => (
            <FormItem className='w-full'>
              <FormLabel className='mt-2'>Search Location</FormLabel>
              <FormControl>
                <VisualizationField
                  defaultValue={field.value}
                  searchValue={field.value}
                  visualization={'autocomplete'}
                  selectedValue={formValues.address}
                  onSelectedValueChange={(value) => {
                    if (placesService && value) {
                      placesService.getDetails(
                        {
                          placeId: value,
                          fields: googleBusinessFields
                        },
                        (res) => {
                          if (res) {
                            const address = {
                              geo: {
                                lat: res.geometry.location.lat(),
                                lng: res.geometry.location.lng(),
                              }
                            };

                            res.address_components.forEach((component) => {
                              if (component?.types?.includes('locality')) {
                                address.address_1 = component.short_name;
                              }
                              if (component?.types?.includes('administrative_area_level_2')) {
                                address.city = component.short_name;
                              }
                              if (component?.types?.includes('administrative_area_level_1')) {
                                address.state = component.short_name;
                              }
                              if (component?.types?.includes('country')) {
                                address.country = component.short_name;
                              }
                              if (component?.types?.includes('postal_code')) {
                                address.zip = component.short_name;
                              }
                            });

                            setValue('address', address);
                            field.onChange(res.formatted_address);
                          }
                        }
                      );
                    }
                  }}
                  onSearchValueChange={(value) => {
                    field.onChange(value);
                    const params = { input: value };
                    if (formValues.address?.country) {
                      params.locationBias = {
                        lat: formValues.address.geo.lat,
                        lng: formValues.address.geo.lng
                      }
                    }
                    getPlacePredictions(params);
                  }}
                  items={placePredictions.map((prediction) => ({
                    value: prediction.place_id,
                    label: prediction.description
                  }))}
                  isLoading={isPlacePredictionsLoading}
                  placeholder="Search location..."
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="locationNotes"
          render={({ field }) => (
            <FormItem className={"w-full"}>
              <FormLabel>Additional Location Notes</FormLabel>
              <FormControl>
                <Input placeholder="Location Notes" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </div>
  )
}

export default EventLocationSection;
