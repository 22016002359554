import CardContainer from "@repo/ui/card/CardContainer";
import Pagination from "@repo/ui/card/Pagination";
import OneVsOnePairingCard from "@/components/Cards/Variants/Pairings/OneVsOne/OneVsOneCard";
import ByeCard from "@/components/Cards/Variants/Pairings/ByeCard";
import Loading from "@/components/Loading";
import { Input } from "@repo/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectTextItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/select";

const OrganizePairings = ({ filters, isFetching, pairings, pagination, searchInput, setFilters, setSearchInput, activePhasePod }) => {
  const isPreview = activePhasePod.status === 'preview';
  
  return (

    <CardContainer className='relative z-10 px-2'>
      <div className="flex flex-row gap-4 p-2 justify-start mt-2">
        <div>
          <p className='text-sm mb-1'>Find User</p>
          <Input
            onChange={(e) => {
              setFilters({ ...filters, page: 1 });
              setSearchInput(e.target.value);
            }}
            placeholder='Search by Name...'
            className='w-[250px] h-9 rounded-md border border-white bg-transparent px-3 py-2 text-sm shadow-sm'
            value={searchInput}
          />
        </div>
        <div>
          <p className='text-sm mb-1'>Pairing Status</p>
          <Select defaultValue={'all'} onValueChange={(value) => {
            setFilters({ page: 1, resultSubmitted: value === 'all' ? null : value === 'submitted' });
          }}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select a Pairing Status" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectTextItem value="all">Show All</SelectTextItem>
                <SelectTextItem value="remaining">Show Not Submitted</SelectTextItem>
                <SelectTextItem value="submitted">Show Submitted</SelectTextItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </div>
      {isFetching ? (
        <div className='flex items-center justify-center h-72'>
          <Loading />
        </div>
      ) : (
        <div className='flex flex-col gap-2 my-2'>
          {pairings?.length > 0 ? pairings?.map((pairing) => (
            <>
              {pairing?.tournamentPairingUsers.filter(Boolean).length === 1 ? (
                <ByeCard pairing={pairing} />
              ) : <OneVsOnePairingCard pairing={pairing} isPreview={isPreview} />}
            </>
          )) : (
            <div className='flex items-center justify-center w-full h-[250px] p-8 uppercase text-2xl font-bold text-zinc-100/80'>
              No Pairings Found
            </div>
          )}
        </div>
      )}
      <Pagination
        data={pagination}
        filters={filters}
        setActivePage={(pageNum) => {
          setFilters({ ...filters, page: pageNum })
        }}
      />
    </CardContainer>

  )
}

export default OrganizePairings;
