export const getGameColor = (gameId) => {
  const colors = {    
    // Lorcana - Blue
    '3162cfb9-9a74-465c-ab13-55459f3f7cc3': 'bg-blue-600 hover:bg-blue-600',
    
    // Magic: The Gathering - Purple
    '0328e4f9-5ce0-47de-9817-c35eb836a668': 'bg-purple-600 hover:bg-purple-600',
    
    // Flesh and Blood - Red (more subdued)
    '1ceb00e9-28ea-45ac-8dfb-c84957f75ed1': 'bg-red-800 hover:bg-red-800',
    
    // One Piece Card Game - Amber
    '74fac80c-c965-476c-952e-f6d1b2a35abc': 'bg-amber-600 hover:bg-amber-600',
    
    // Sorcery - Emerald
    '74fac80c-c965-476d-9f2e-f6f1b2f35123': 'bg-emerald-600 hover:bg-emerald-600',
    
    // UniVersus - Indigo
    'a95493de-6660-409c-abcc-8ccbcd446f2c': 'bg-indigo-600 hover:bg-indigo-600',
    
    // Alpha Clash - Rose
    '74fac80c-c965-476c-952e-d6d1b2a35342': 'bg-rose-600 hover:bg-rose-600',
    
    // Digimon - Cyan
    '7f1a90ea-327c-4165-9b35-5195c2415e0e': 'bg-cyan-600 hover:bg-cyan-600',
    
    // Elestrals - Teal
    '19c766b8-9a11-4747-ae8a-74a380ba1a95': 'bg-teal-600 hover:bg-teal-600',
    
    // Game of Skate - Orange
    'c87ff406-443b-44db-801c-9b8a473e66a8': 'bg-orange-600 hover:bg-orange-600',
    
    // Kryptik - Lime
    'f7f75901-1d2b-4f39-870c-d1f982e93381': 'bg-lime-600 hover:bg-lime-600',
    
    // Necromansters - Violet
    '0cc15f08-53dc-4baf-8649-af1b6c34f7dd': 'bg-violet-600 hover:bg-violet-600',
    
    // Round Table - Pink
    '19cdfed7-f38a-453b-aa0d-6f42c3be0934': 'bg-pink-600 hover:bg-pink-600',
    
    // System Gate - Sky
    'abb64f80-d8f7-4b87-914a-fe25c98c6918': 'bg-sky-600 hover:bg-sky-600',
    
    // The World of Kylia - Fuchsia
    '8e481c51-928c-4a69-a148-8421e706d3d6': 'bg-fuchsia-600 hover:bg-fuchsia-600',
    
    // Chronicles of Arcane - Yellow
    '04e41301-b836-428f-9876-e0c99bfb2fff': 'bg-yellow-600 hover:bg-yellow-600',
    
    // Neuroscape - Emerald (lighter)
    'd8194da1-5524-4e42-a6ac-ea4641d96060': 'bg-emerald-500 hover:bg-emerald-500',
    
    // Wonders of The First - Blue (lighter)
    '907f6223-9f5a-429b-97f8-bba1fe663983': 'bg-blue-500 hover:bg-blue-500',
    
    // MetaZoo - Indigo (lighter)
    '69055d9b-147c-4dc8-8756-2761bb713ab3': 'bg-indigo-500 hover:bg-indigo-500',
    
    // Default color for unknown games - Gray
    'default': 'bg-zinc-600 hover:bg-zinc-600'
  };

  return colors[gameId] || colors.default;
}; 