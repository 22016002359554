import {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@repo/ui/modal';

import { Button } from "@repo/ui/button";
import { webAxiosInstance } from "@/utils/axios";
import Typography from "@repo/ui/typography";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { queryClient } from '@/lib/queryClient';
import { useParams } from '@tanstack/react-router';
import {updateOrganizationMemberMutationFn} from "@repo/api/base";
import {toast} from "sonner";
import {useMutation} from "@tanstack/react-query";

const EnableMemberModal = ({ handleClose, memberInfo }) => {
  const { orgId } = useParams({ strict: false })
  const router = null;

  const enableOrgUserMutation = useMutation({
    mutationFn: updateOrganizationMemberMutationFn,
    onSuccess: () => {
      router && router.refresh();
      toast.success(`Successfully enabled ${memberInfo?.user?.firstName}!`, {
        description: `Thank you for updating ${memberInfo?.user.firstName} ${memberInfo?.user?.lastName}. If you would like to change this, please re-disable the user on the Organization Members table.`,
      });
      handleClose && handleClose();
      queryClient.invalidateQueries({ queryKey: ['org', orgId, 'members']});
    },
    onError: () => {
      toast.error("We apologize for this inconvenience.", {
        description: "There was an error with disabling the org member. Please reach out via email. We look forward to hearing from you!",
        action: {
          label: "Email Us",
          onClick: () => window.location.href = `mailto:support@carde.io?subject=Issue Disabling Organization User`,
        },
      })
    },
  })

  const onSubmit = () => {
    enableOrgUserMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      orgUserId: memberInfo.id,
      data: {
        disabled: false,
      }
    });
  };

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle>Enable {memberInfo?.user?.firstName} {memberInfo?.user?.lastName}</ModalTitle>
      </ModalHeader>
      <div className='flex flex-col items-center text-center'>
        <ExclamationCircleIcon className="h-12 w-12 text-green-500 mr-1" aria-hidden="true" />
        <Typography className='text-xl my-4'>
          Are you sure you would like to enable {memberInfo?.user?.firstName}?
        </Typography>
        <Typography className='text-xs italic text-zinc-100/60'>
          Note: Enabling this user will grant them access to this organization.
        </Typography>
      </div>
      <ModalFooter className='items-end'>
        <div>
          <Button
            onClick={handleClose}
            style={{ padding: '0.75rem 2rem', fontSize: '0.75rem' }}
            variant='text'
          >
            No, Do Not Enable
          </Button>
          <Button
            className='h-fit'
            dataTooltipTarget="tooltip-default"
            variant='gradient-contained'
            style={{ padding: '0.75rem 2rem', fontSize: '0.875rem' }}
            onClick={onSubmit}
          >
            Enable Member
          </Button>
        </div>
      </ModalFooter>
    </ModalContent>
  )
};

export default EnableMemberModal;
