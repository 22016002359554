import { useState } from "react";
import { RiArrowRightLine } from "@remixicon/react";

import { Button } from "@repo/ui/button";
import { Modal, ModalTrigger } from "@repo/ui/modal";
import SkipToNextPhaseModal from "@/components/Modal/Event/Organize/SkipToNextPhaseModal";
import FinalizeActivityModal from "@/components/Modal/Event/Organize/FinalizeActivityModal";
import { useEventStore } from "@/store/useEventStore";

const NonTournamentPairing = () => {
  const { activePhasePod, allPhases, currentTournament } = useEventStore();
  const [isSkipToNextPhaseModalOpen, setIsSkipToNextPhaseModalOpen] = useState(false);
  const [isFinalizeActivityModalOpen, setIsFinalizeActivityModalOpen] = useState(false);

  const showRecommendedFinalizeActivity = allPhases?.length === activePhasePod?.stage && currentTournament?.currentRound === currentTournament?.originalRounds;
  const nextPhaseForPod = allPhases?.find(phase => phase?.pod === activePhasePod?.pod && phase?.stage === activePhasePod?.stage + 1);


  if (activePhasePod?.status === 'complete') {
    return (
      <div className="bg-zinc-950/90 flex flex-col items-center justify-center h-full w-full rounded-lg py-[96px] mt-8">
        <h2 className='text-xl font-bold uppercase tracking-[2px] z-10'>Phase Complete</h2>
      </div>
    )
  }
  const SkipToNextPhaseModalContainer = () => {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <Modal open={isSkipToNextPhaseModalOpen} onOpenChange={setIsSkipToNextPhaseModalOpen}>
          <ModalTrigger asChild>
            <Button className='bg-zinc-950/70 flex flex-col items-center justify-center border-2 rounded-lg py-[48px]' variant='text'>
              {activePhasePod?.name && nextPhaseForPod?.name
                ?
                <h2 className='text-xl font-bold uppercase tracking-[2px]'>Advance from {activePhasePod?.name} to {nextPhaseForPod?.name}</h2>
                :
                <h2 className='text-xl font-bold uppercase tracking-[2px]'>Advance to Next Phase</h2>
              }
              <RiArrowRightLine className='w-8 h-8' />
            </Button>
          </ModalTrigger>
          <SkipToNextPhaseModal handleClose={() => setIsSkipToNextPhaseModalOpen(false)} />
        </Modal>
      </div>
    )
  }

  const FinalizeActivityModalContainer = () => {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <Modal open={isFinalizeActivityModalOpen} onOpenChange={setIsFinalizeActivityModalOpen}>
          <ModalTrigger asChild>
            <Button className='bg-zinc-950/70 flex flex-col items-center justify-center border-2 rounded-lg py-[48px]' variant='text'>
              <h2 className='text-xl font-bold uppercase tracking-[2px]'>Finalize Event</h2>
              <RiArrowRightLine className='w-8 h-8' />
            </Button>
          </ModalTrigger>
          <FinalizeActivityModal handleClose={() => setIsFinalizeActivityModalOpen(false)} />
        </Modal>
      </div>
    )
  }

  return (
    <div className='w-full p-4 bg-zinc-950 rounded-lg flex items-center justify-center gap-4 mt-8 h-[300px]'>
      {showRecommendedFinalizeActivity ? (
        <FinalizeActivityModalContainer />
      ) : (
        <SkipToNextPhaseModalContainer />
      )}
    </div>
  )
}

export default NonTournamentPairing;
