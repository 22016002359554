import { show } from "@intercom/messenger-js-sdk";
import React, { useEffect, useRef, useState } from "react";
import Lottie from "lottie-react";
import AssistantIcon from "./AssistantIcon";
import assistantAnimation from "../../assets/assistant-animation.json";

const IntercomCardeBot = () => {
  const [triggerHoverAnimation, setTriggerHoverAnimation] = useState(false);
  const lottieRef = useRef();

  useEffect(() => {
    if (!triggerHoverAnimation) {
      setTimeout(() => setTriggerHoverAnimation(true), 1000);
    }
  }, [triggerHoverAnimation]);

  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current.setSpeed(0.8);
    }
  }, [lottieRef]);

  return (
    <div className="fixed bottom-48 left-6 z-50">
      <button
        onClick={() => show()}
        onMouseEnter={() => setTriggerHoverAnimation(true)}
        className="transition-transform duration-500 ease-in-out hover:scale-105 bg-transparent"
      >
        {triggerHoverAnimation ? (
          <Lottie
            ref={lottieRef}
            animationData={assistantAnimation}
            loop={false}
            className="h-[160px] w-[106px]"
          />
        ) : (
          <AssistantIcon height="100" width="112" />
        )}
      </button>
    </div>
  );
};

export default IntercomCardeBot;
