import React from "react";

import { ModalContent, ModalHeader, ModalTitle } from "@repo/ui/modal";
import OneVsOneReportPairing from "@/components/Modal/Event/Pairings/ManagePairing/OneVsOne";

const ManagePairingModal = ({ handleClose, pairing, isPreview }) => {
  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle>Manage Pairing</ModalTitle>
      </ModalHeader>

      <OneVsOneReportPairing handleClose={handleClose} pairing={pairing} isPreview={isPreview} />
    </ModalContent>
  )
}

export default ManagePairingModal;
